import {useFrame} from '@react-three/fiber'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Vector2, Color, BufferAttribute, PlaneGeometry } from 'three';
import { introFragment, introVertex } from '../fractal/introBackgroundFractals';
import { lerp } from 'three/src/math/MathUtils';
import easeInOutQuad from '../../../Helpers/easeInOutQuad';
import gsap from 'gsap';

import { deviceType } from 'react-device-detect';


export default function IntroBackground(props)
{
    const mesh = useRef(null);
    const scroll = useRef(0);
    const ORIGINAL_SCALE = (deviceType === "browser") ? 9 : 4;
    const scaleAnimation = useRef(1);

    const updatedPositions = useMemo(() =>
    {
        const geo = new PlaneGeometry(10,10,300,300).toNonIndexed()

        //let normals = geo.getAttribute('normals');
        let positions = geo.getAttribute('position').array;

        let length = positions.length;

        let np2 = new Float32Array(length);

        for(let i = 0; i < length; i+=9)
        {
            let rand = Math.random() * 5;
            let neg = Math.random() > .5 ? 1 : -1;
            let rn = rand * neg * .5;
            let rn2 = Math.random() * 10 * neg;
            let rn3 = Math.random() * -100;
            

            np2[i] = positions[i] + (rn);
            np2[i + 1] = positions[i + 1] + (rn2);
            np2[i + 2] = positions[i + 2] + (rn3);
            np2[i + 3] = positions[i + 3] + (rn);
            np2[i + 4] = positions[i + 4] + (rn2);
            np2[i + 5] = positions[i + 5] + (rn3);
            np2[i + 6] = positions[i + 6] + (rn);
            np2[i + 7] = positions[i + 7] + (rn2);
            np2[i + 8] = positions[i + 8] + (rn3 );
        }
        geo.setAttribute('np', new BufferAttribute(np2, 3));
        geo.setAttribute('op', new BufferAttribute(positions, 3));
        

        return {positions: positions, np: np2, geo: geo}

    }, [  ])



    function handleTransitionToHovered(delta)
    {
        if(mesh.current.material.uniforms.u_hovered.value == 0)
        {
            mesh.current.material.uniforms.u_hovered.value = 1;
        }

        mesh.current.material.uniforms.u_time2.value = mesh.current.material.uniforms.u_time2.value + delta;
        mesh.current.material.uniforms.u_time.value = mesh.current.material.uniforms.u_time.value + delta ;

        //decrement u_transition1
        mesh.current.material.uniforms.u_transition1.value = mesh.current.material.uniforms.u_transition1.value - delta * 40;
    }

    function handleHovered(delta)
    {
        mesh.current.material.uniforms.u_time2.value = mesh.current.material.uniforms.u_time2.value + delta;
    }

    function phase1(delta)
    {
        if(mesh.current.material.uniforms.u_opacity.value == 0) return;

        if(mesh.current.material.uniforms.u_transition1.value > 0)
        {
            mesh.current.material.uniforms.u_transition1.value = mesh.current.material.uniforms.u_transition1.value - delta * 100;
        }

        else if(mesh.current.material.uniforms.u_transition2.value > 0)
        {
            mesh.current.material.uniforms.u_transition2.value = mesh.current.material.uniforms.u_transition2.value - delta * 100;
        }

        mesh.current.material.uniforms.u_time2.value = mesh.current.material.uniforms.u_time2.value + delta;
        mesh.current.material.uniforms.u_time3.value = mesh.current.material.uniforms.u_time3.value + delta;
    }

    function phase2(delta)
    {

        if(mesh.current.material.uniforms.u_opacity.value == 0) return;

        let v = mesh.current.material.uniforms.u_scrollTime.value + delta * .3;
        mesh.current.material.uniforms.u_opacity.value = lerp(1, 0, v);

        mesh.current.material.uniforms.u_scrollTime.value = v;

        if(mesh.current.material.uniforms.u_opacity.value <= 0)
        {
            props.setBgLoaded(true)

            props.setDen(true)
        }

    }

    function handleTransitionFromHovered(delta)
    {
        //ensure hovered state is tracked
        if(mesh.current.material.uniforms.u_hovered.value == 1)
        {
            mesh.current.material.uniforms.u_hovered.value = 0;
        }

        //increment both time values
        mesh.current.material.uniforms.u_time2.value = mesh.current.material.uniforms.u_time2.value + delta;
        mesh.current.material.uniforms.u_time.value = mesh.current.material.uniforms.u_time.value + delta;

        //increment u_transition1 if applicable
        if(mesh.current.material.uniforms.u_transition1.value < 100)
        {
            mesh.current.material.uniforms.u_transition1.value = mesh.current.material.uniforms.u_transition1.value + delta * 40;
        }
    }

    function handleDefault(delta)
    {
        mesh.current.material.uniforms.u_time.value = mesh.current.material.uniforms.u_time.value + delta;

        if(mesh.current.material.uniforms.u_time2.value > 0)
        {
            mesh.current.material.uniforms.u_time2.value = 0;
        }

    }

    const uniforms = useMemo(() => ({
        u_init: {value: 100.0},
        u_time: {value: 5.0},
        u_time2: {value: 0.0},
        u_time3: {value: 6.0},
        u_ambientTime: {value: 0.},
        u_opacity: {value: 1.},
        u_scrollTime: {value: 0.},
        u_unscrollTime: {value: 0.},
        u_transition1: {value: 100.0},
        u_transition2: {value: 100.0},
        u_hovered: {value: 0.0},
        u_opacity: {value: 1},
        u_phase: {value: 0.0},
        u_scroll: {value: 0.0},
        u_scrollTransition: {value: 0.},
        u_timeflop: {value: 0.},
    }), []);

    useFrame((_, delta) =>
    {
        if(props.den == true)  return;

        if(props.phase === undefined) return;
        if(!mesh.current) return;

        if(mesh.current.material.uniforms.u_time.value > 20)
        {
            if(mesh.current.material.uniforms.u_timeflop.value < 2)
            {
                mesh.current.material.uniforms.u_timeflop.value = mesh.current.material.uniforms.u_timeflop.value + delta;
            }
            else
            {
                mesh.current.material.uniforms.u_timeflop.value = 0;
                mesh.current.material.uniforms.u_time.value = 2;
            }
        }

        if(mesh.current.material.uniforms.u_time2.value > 5000)
        {
            mesh.current.material.uniforms.u_time2.value = 0;
        }

        if(mesh.current.material.uniforms.u_time3.value > 5000)
        {
            mesh.current.material.uniforms.u_time3.value = 0;
        }

        if(mesh.current.material.uniforms.u_init.value > 0)
        {
            mesh.current.material.uniforms.u_init.value = mesh.current.material.uniforms.u_init.value - delta * 40;
        }

        if(props.hasTransitioned == true)
        {

            if(scaleAnimation.current > 0)
            {
                let ev = easeInOutQuad(scaleAnimation.current);
                let scaleCurr = lerp(2, ORIGINAL_SCALE, ev);
                scaleAnimation.current -= delta * .3;

                mesh.current.scale.setX(scaleCurr);
                mesh.current.scale.setY(scaleCurr);
            }


            if(mesh.current.scale.x < 4.6)
            {
                phase2(delta);
            }
        }

        if(props.phase == 0)
        {
            //check if hovered
            if(props.hovered === true)
            {
                if(mesh.current.material.uniforms.u_transition1.value > 0)
                {
                    handleTransitionToHovered(delta);
                    return;
                }
                handleHovered(delta);
                return;
            }
            if(mesh.current.material.uniforms.u_transition1.value < 100)
            {
                handleTransitionFromHovered(delta);
                return;
            }

            handleDefault(delta);
            return;
        }
        if(props.phase == 1)
        {
            
            if(mesh.current.material.uniforms.u_phase.value == 0)
            {
                mesh.current.material.uniforms.u_phase.value = 1.;
                mesh.current.material.uniforms.u_hovered.value = 0.;
            }
            phase1(delta);
            
        }
        if(props.phase == 2)
        {
            phase1(delta);
            phase2(delta);
        }
    });



    return(
        <>
            <mesh ref = {mesh} {...props} geometry={updatedPositions.geo}>
                <shaderMaterial 
                    vertexShader={introVertex}
                    fragmentShader = {introFragment}
                    uniforms = {uniforms}
                    transparent = {true}
                    opacity={1}
                    wireframe = {false}
                />
            </mesh>

        </>
    )
}