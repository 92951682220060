import { SidebarItemsMobile } from "../SidebarComponents/SidebarItems2";


export default function MobileSidebar({page, setPage, mobileSidebarOpen, setMobileSidebarOpen})
{
    if(mobileSidebarOpen === true)
    {
        return(
            <SidebarItemsMobile 
            page = {page}
            setPage={setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />
        )
    }
    
    return null;
    
}