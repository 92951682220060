
import styles from '../Common/page.module.css';
import '../../../../css/keyFonts.css'

export const P = ({children}) => {
    return <p className = {`${styles.Pg} HN`}>{children}</p>
}

export const P2 = ({children}) => {
    return <span className = {`${styles.Pg} HN`}>{children}</span>
}


export const H = ({children}) => {
    return <h2 className = {`${styles.Header} HN`}>{children}</h2>
}

export const H2 = ({children}) => {
    return <h2 className = {`${styles.H2} HN`}>{children}</h2>
}

export const PH = ({children}) => {
    return <h2 className={styles.PageHeader}>{children}</h2>
}

export const White = ({children}) => {
    return <span className={styles.White}>{children}</span>
}


export const S = ({children}) => {
    return <div className = {styles.Space}/>
}

export const Tab = () => {
    return(
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
    )
}

export const Ul = ({children}) => {
    return(
        <ul className = {styles.Ul}>
            {children}
        </ul>
    )
}

export const Li = ({children}) => {
    return(
        <li className = {styles.Li}>
            {children}
        </li>
    )
}

export const BigLi = ({children}) => {
    return(
        <li className = {styles.BigLi}>
            {children}
        </li>
    )
}

export const Sm = ({children, props}) => {
    return(
        <span styles = {(props?.style) ? props.style : ''} className={`${styles.Smaller} HN`}>
            {children}
        </span>
    )
}