import { isDesktop, isMobile } from 'react-device-detect';
import styles from '../../InfoSections/InfoSectionScaffold.module.css';
import Piechart from './PieChart.svg';

export default function TokenomicsTextcontent()
{
    const SEPARATOR = (
        <hr style = {{width: '100%', border: 'none', borderBottom: '1px solid #5c5c5c',
            borderTop: "1px solid #0f0f0f"}}/>
    )

    const LIGHTSEPARATOR = (
        <hr style = {{width: '100%', border: 'none', borderBottom: '1px dashed #5c5c5c'}}/>
    )



    return(
        <div style = {{
            width: '100%',
            height: '100%',
            pointerEvents: 'all', 
            display: 'flex', 
            flexDirection: (isDesktop) ? 'row' : 'column',
            justifyContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',}}>
            <img style = {{width: "60%"}} src = {Piechart} alt = "tokenomics pie chart"/>
            <div style = {{display: 'flex', flexDirection: 'column', alignItems: 'center',
            overflowY: 'scroll', height: "100%", width: (isDesktop) ? "40%":"90%"}}>
            <p className={styles.TLabel1} >
                {SEPARATOR}
                <b>Name:</b> <span className={styles.InnerText}>HoneyBadger Token (HB)</span>
                </p>
                <p className={styles.TLabel}>
                <b>Total Supply:</b> <span className={styles.InnerText}>10,000,000</span>
                </p>
                <p className={styles.TLabel}>
                <b>Token Type:</b> <span className={styles.InnerText}>ERC-20</span>
                </p>
                <p className={styles.TLabel}>
                <b>Chain:</b> <span className={styles.InnerText}>Ethereum</span>
                </p>
                <p className={styles.TLabel} style = {{paddingBottom: "20px"}}>
                    <b>Purpose: </b> 
                    <span className={styles.InnerText}>
                        HoneyBadger Token is a utility token designed to power the HoneyBadger ecosystem. 
                        From launch, the token will enable staking functionality, rewarding participants for their engagement. 
                        In the future, it will also serve as the foundation for community governance, 
                        empowering users to participate in key decisions for the ecosystem.
                    </span>

                    {SEPARATOR}

                    <b style = {{fontSize: '20px'}}>Staking</b><br/>
                    <span className={styles.InnerText}>
                        HoneyBadger Token offers unique staking functionality to engage and reward holders:
                    </span>
                    <br/><br/>
                    <b>How it Works: </b> 
                    <span className={styles.InnerText}>
                        When tokens are staked or unstaked, 15% of the staked amount is distributed proportionally 
                        to all current stakers, fostering a collaborative ecosystem (resulting in a 22.5% total staking and unstaking fee).
                    </span>
                    <br/><br/>
                    <b>Optimized for Efficiency: </b>
                    <span className={styles.InnerText}>
                        The staking system has been designed to minimize the computational cost of claiming rewards, reducing the impact 
                        of Ethereum gas fees by approximately 4x compared to standard implementations. This is due to novel optimizations 
                        in our implementation, which decreases the cost of claim operations.
                    </span>

                    {SEPARATOR}
                    <b style = {{fontSize: '20px'}}>Currently-Planned Future Utility</b><br/>
                    <span className={styles.InnerText}>
                        As the HoneyBadger framework evolves, the token will unlock additional practical applications:
                    </span>
                    <br/><br/>
                    <b>Governance Participation: </b> 
                    <span className={styles.InnerText}>
                        Token holders will help guide the evolution of the HoneyBadger system through decentralized governance.
                    </span>
                    <br/><br/>
                    <b>Framework Deployment Discounts: </b> 
                    <span className={styles.InnerText}>
                        Tokens can be exchanged for discounted licenses to deploy projects using the HoneyBadger framework, reducing barriers for developers.
                    </span>
                    <br/><br/>
                    <b>Ecosystem Grants: </b> 
                    <span className={styles.InnerText}>
                        Promising projects can receive HoneyBadger tokens to incentivize growth and engage with the wider community 
                        through events like airdrops or direct integrations.
                    </span>

                    {SEPARATOR}

                    <b style = {{fontSize: '20px'}}>Token Allocation Categories </b> 

                    <br/><br/><b>Community Allocation (51.5% Total): </b>
                    <br/>
                    <span className={styles.InnerText}>• Targeting 40% of community tokens released immediately 
                    after launch, vesting in the first month
                    (airdrops and incentives for early adopters, engagement, and peer-to-peer activity).</span>
                    <br/><br/>
                    <span className={styles.InnerText}>• Remaining 60% of community tokens projected to be released over 
                    36 months via ongoing events including continued airdrops, engagement rewards, 
                    community development rewards, contests, and governance participation.  
                    Batches of un-released community tokens may be burned depending on community 
                    sentiment.</span>
                    <br/><br/><b style = {{color: "#3e9c35"}}>Wallet Type: </b> LOCKED - admins can perform 
                    airdrop and burn operations.

                    {LIGHTSEPARATOR}

                    <b>Staking Pool Dividends Boost: </b>
                    <br/>
                    <span className={styles.InnerText}>
                    • Admins can enable and disable the 
                    dividends boost feature as a way to encourage engagement.  The maximum 
                    boost is a 25% boost to all dividends distributed.  This token pool will not 
                    be burned, but they may not be released in full.
                    </span>
                    <br/><br/>
                    <b style = {{color: "#3e9c35"}}>Wallet Type: </b> LOCKED - admins can only 
                    enable or disable the corresponding feature.
                    {LIGHTSEPARATOR}
                    <b>Marketing Reserve: </b>
                    <br/>
                    <span className={styles.InnerText}>
                    • The marketing reserve is a hassle-free admin fund that will be 
                    used to ensure consistent external marketing efforts.  Our plan is 
                    to maintain steady external marketing activity, rather than stagnating 
                    between big launches.  Our marketing approach will center around sponsoring 
                    innovative projects, partnerships, influencer marketing, contests, and social 
                    media activity.
                    </span>
                    <br/><br/>
                    <b style = {{color: "#3e9c35"}}>Wallet Type: </b> UNRESTRICTED
                    
                    {LIGHTSEPARATOR}
                    <b>Liquidity Pool Tokens: </b>
                    <br/>
                    <span className={styles.InnerText}>
                    • While this pool is rather large, its excess is actually 
                    intended as a fail-safe.  Once the liquidity pool has achieved 
                    stability, all remaining tokens will be burned.  
                    <br/><br/>
                    </span>
                    <b style = {{color: "#3e9c35"}}>Wallet Type: </b> 
                    LOCKED - Can transfer to Uniswap or burn.  

                    {LIGHTSEPARATOR}
                    <b>Team Reserve: </b>
                    <br/>
                    <span className={styles.InnerText}>
                    The team reserve is allocated to support the long-term development, 
                    innovation, and operational sustainability of the HoneyBadger framework, 
                    ensuring the system remains cutting-edge and competitive over time.
                    <br/><br/>
                    On launch day, 20% of the team tokens will be immediately available.  
                    The remaining amount will vest continuously over the course of 2 years.
                    </span><br/><br/>
                    <b style = {{color: "#3e9c35"}}>Wallet Type: </b> 
                    CONTROLLED RELEASE - Tokens are centrally managed, but made available 
                    gradually over a long period of time.

                    {LIGHTSEPARATOR}

                    <b>Owner Tokens: </b>
                    <br/>
                    <span className={styles.InnerText}>
                    20% of owner tokens will be available on launch day.  The remaining 
                    80% will vest over the course of 2 years.
                    <br/><br/>
                    </span>
                    <b style = {{color: "#3e9c35"}}>Wallet Type: </b> 
                    CONTROLLED RELEASE - Tokens are gradually vested
                    over a period of time.
                    {LIGHTSEPARATOR}
                    <b>Token Grants: </b>
                    <br/>
                    <span className={styles.InnerText}>
                    Token grants allow us to support promising and innovative 
                    projects that leverage HoneyBadger, providing a community-approved 
                    bundle of tokens that can be used to drive wider engagement or 
                    directly integrated into their own systems.  The central purpose is to give 
                    HoneyBadger projects an even bigger competitive edge, and to create a 
                    natural link between the central community and projects using the framework. 
                    <br/><br/>
                    </span>
                    <b style = {{color: "#3e9c35"}}>Wallet Type: </b> 
                    LOCKED - Tokens are presently immovable.  Grants system will 
                    be open-sourced and introduced through governance after HoneyBadger 
                    framework release.
                    {LIGHTSEPARATOR}
                </p>
            </div>
        </div>
    )
}