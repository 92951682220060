import React, {  useEffect, useRef, useState, useMemo } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import {  useGLTF } from "@react-three/drei";
import {  Vector3 } from "three";
import gsap from "gsap";
import { useAnimations } from "@react-three/drei";
import { isMobile } from "react-device-detect";
import { shaderMaterialHoneyBadger } from "./helpers/shaderMaterials";
import { setStartEndPos } from "./helpers/setStartEndPos";
import { handleHoverOut } from "./helpers/handleHoverOut";
import { handleHover } from "./helpers/handleHover";
import { handleBadgerUniformChangeOnHover } from "./helpers/handleBadgerUniformChangeOnHover";
import playAmbientAnimation from "./helpers/playAmbientAnimation";
import handleActions from "./helpers/handleActions";

export const HoneyBadgerModelMainUpdated = (props) => 
{
    const r = useRef();
    const r2 = useRef();
    const r3 = useRef();
    const ref = useRef();
    const wait = useRef(0);
    const elapsed = useRef(0);
    const HoverOut = useRef(null);
    const stoppedWalking = useRef(false);

    const [endPos, setEndPos] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [startPos, setStartPos] = useState(null);
    const [ambientA, setAmbientA] = useState(null);
    const [cameraPos, _] = useState(new Vector3());
    const [hasHovered, setHasHovered] = useState(false);

    const {camera} =  useThree();
    const { nodes, animations } = useGLTF("/hbupdated.glb");
    const { actions, names } = useAnimations(animations, ref);

    const shaderMatBack = useMemo(() => shaderMaterialHoneyBadger(true), []);
    const shaderMatBody = useMemo(() => shaderMaterialHoneyBadger(false), []);

    if(ref.current != undefined && !HoverOut.current)
    {
        HoverOut.current = gsap.fromTo(camera.position, 
            {z: cameraPos.z}, 
            {z: camera.position.z - .15, paused: true, duration: .2});
    }

    useEffect(() => setStartEndPos({setStartPos, setEndPos}), []);
    useEffect(() => playAmbientAnimation(hasHovered, ref, startPos, endPos, setAmbientA), 
        [startPos, endPos]
    );
    useEffect(() => handleActions(actions, names), [actions, names]);

    useEffect(() =>
    {
        document.body.style.cursor = ((hovered) ? 'pointer':'auto');

        if(hovered && !hasHovered)
        {
            ambientA.pause();

            let center = gsap.timeline({paused: true})
            center.to(ref.current.position, {x: 0, duration: 1}).to(ref.current.rotation, {y: Math.PI/2})
            center.play();

            if(!stoppedWalking.current)
            {
                if(!actions || !names) 
                {
                    return;
                }
                let firstAction = actions[names[0]];

                let remainingTime = firstAction.getClip().duration - firstAction.time;

                // Slow down to finish exactly at the end of the animation
                gsap.to(firstAction, {
                    timeScale: 0,
                    duration: remainingTime,
                    ease: "none",
                });
                
                stoppedWalking.current = true;
            }

            setHasHovered(true);
        }

        if(cameraPos !== undefined)
        {
            cameraPos.x = camera.position.x;
            cameraPos.y = camera.position.y;
            cameraPos.z = camera.position.z;
        }

        if(hovered == true)
        {
            r.current.material.uniforms.u_hovered.value = 1;
            r2.current.material.uniforms.u_hovered.value = 1;
        }
        else
        {
            r.current.material.uniforms.u_hovered.value = 0;
            r2.current.material.uniforms.u_hovered.value = 0;
        }
    }, [hovered])

    useEffect(() => {
        if (r.current) {
            r.current.geometry.computeBoundingBox();
            r.current.geometry.computeBoundingSphere();
        }
        if (r2.current) {
            r2.current.geometry.computeBoundingBox();
            r2.current.geometry.computeBoundingSphere();
        }
    }, [loaded]);

    useFrame((_, delta) =>
    {
        if(loaded === false)
        {
            if(!r.current) return;
            if(wait.current < 1)
            {
                wait.current += delta;
                return;
            }
            else if(r.current.material.uniforms.u_opacity.value < 1.)
            {
                r.current.material.uniforms.u_opacity.value = r.current.material.uniforms.u_opacity.value + delta * .35;
                r2.current.material.uniforms.u_opacity.value = r2.current.material.uniforms.u_opacity.value + delta * .35;
            }
            else
            {
                setLoaded(true)
            }
        }
        //has entered the screen, has not been clicked
        else if(r.current != undefined)
        {
            handleBadgerUniformChangeOnHover(hovered, r, r2, delta);
        }
        if(elapsed.current <= 4) elapsed.current += delta;
    })

    return (
        <group  
        dispose={null} ref={ref}  {...props} position = {[endPos, -6, -10]}
        onPointerOver={(e) => {
            //e.stopPropagation(); // Prevent the event from propagating to overlapping meshes
            handleHover({ e, isMobile, r, elapsed, setHovered, props, HoverOut });
        }}
        onPointerOut={(e) => {
            //e.stopPropagation();
            handleHoverOut({ e, isMobile, r, setHovered, props, HoverOut });
        }}
        >
            <group name="Scene"  >
                <group name="rig" >
                    <group name="HoneyBadger"  rotation = {[0, -Math.PI, 0]}>
                        <skinnedMesh
                        ref = {r}
                        frustumCulled = {false}
                        castShadow
                        receiveShadow
                        name="HoneyBadger_1"
                        geometry={nodes.HoneyBadger_1.geometry}
                        material={shaderMatBody}
                        skeleton={nodes.HoneyBadger_1.skeleton}
                        />
                        <skinnedMesh
                        ref = {r2}
                        frustumCulled = {false}
                        castShadow
                        receiveShadow
                        name="HoneyBadger_2"
                        geometry={nodes.HoneyBadger_2.geometry}
                        material={shaderMatBack}
                        skeleton={nodes.HoneyBadger_2.skeleton}
                        />
                        <skinnedMesh
                        ref = {r3}
                        frustumCulled = {false}
                        castShadow
                        receiveShadow
                        name="HoneyBadger_3"
                        geometry={nodes.HoneyBadger_3.geometry}
                        material={shaderMatBody}
                        skeleton={nodes.HoneyBadger_3.skeleton}
                        />
                    </group>
                    <primitive object={nodes.root} />
                    <primitive object={nodes['MCH-torsoparent']} />
                </group>
            </group>
        </group>
    );
};

useGLTF.preload('/hbupdated.glb')