import styles from './MobileContentRoot.module.css';
import '../../../css/keyFonts.css';
import '../../../css/TextStyles.css';
import ContentSection1 from './ContentSection1/ContentSection1';
import ContentSection2 from './ContentSection2/ContentSection2';
import logo from '../../../assets/logoTPB.png'

/*
                <iframe
                    style={{
                        marginTop: "30px",
                        borderRadius: "10px",
                        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)",
                    }}
                    src="https://www.youtube.com/embed/LngHy8ixdvs?start=32"
                ></iframe>
*/

export default function MobileContentRoot(props)
{

    return(
        <div className = {styles.Container}>
            <div className={styles.HeaderSpace} />
            <ContentSection1 />
            <ContentSection2 />

            <div className={styles.FooterContainer}>
                <div className={styles.Col}>
                    <i style = {{color: "white", paddingBottom: "5px", fontSize: "12px"}} className='HN'>
                    HoneyBadger Framework™</i>
                    <i style = {{color: "white", paddingBottom: "5px", fontSize: "12px"}} className='HN'>
                    MEDHINI LLC, Seattle, Washington</i>
                </div>
                <img src = {logo} style = {{width: "50px", height: "50px"}} />
            </div>
        </div>
    )
}