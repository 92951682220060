import styles from './Hamburger.module.css';

import HamburgerImg from '../../../../assets/Hamburger.svg';

export default function Hamburger({mobileSidebarOpen, setMobileSidebarOpen})
{
    return(
        <div 
            className = {styles.Container} 
            onClick = {() => {setMobileSidebarOpen(!mobileSidebarOpen)}}
        >
            <img 
                className={styles.Hamburger} 
                src = {HamburgerImg} 
                alt = "Hamburger menu"
            />
        </div>
    )
}