import { PageWrapper } from "../PageManager";
import styled from 'styled-components'
import { useEffect } from "react";
import { BasicHeading, BasicText, BigHeading } from "../styledComponents";
import gsap from "gsap";
import whb from '../../../../assets/systemwithhb.png'
import nbh from '../../../../assets/systemnohb.png'

export default function Page3_4(props)
{
    const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    `

    const Col1 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    `

    useEffect(() =>
    {
        gsap.to(".t", {width: (props.docked == false) ? "50vmax" : "65vmax", opacity: 1})
    }, [props.docked])

    return(
        <PageWrapper docked = {props.docked}>
            <BigHeading style = {{marginTop: "5vmax"}} className = {"inter" + " " + "fr" + " " + "t"}>
                Technical Deep-Dive
            </BigHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left", fontSize: "1.2vmax"}} className = {"opensans" + " " + "t"}>

                At this point, you have developed a strong understanding of the scalability dilemma, 
                its currently-accepted solutions, their drawbacks, and our proposed alternative: 
                the HoneyBadger Storage System. Finally, we can explore how HoneyBadger works with 
                some specificity.  Note that this section may be difficult to follow at some points for individuals 
                who are not blockchain developers, and there will be some redundancy with respect to the preceeding 
                sections.  This section simply dives into the "core tenets" with concrete specificity: that 
                text alone is largely adequate for those who are not doubtful about the system or 
                technically-inclined.  Note that only the "big three" tenets of scalability, 
                efficiency, and security are outlined here, as the basic "core tenets" section 
                adequately covers more straightforward topics such as ease-of-use and accessibility.  

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                How HoneyBadger Unlocks Unfettered Scalability
            </BasicHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                While we have already allocated a considerable amount of time to outlining 
                smart contract scalability, we are yet to thoroughly turn this 
                investigation towards the HoneyBadger system itself.  From a high-level, 
                it may already be clear how HoneyBadger promotes scalability, given 
                that it is essentially a state-of-the-art external storage pattern implementation. 

            </BasicText>

            <img src = {whb} alt = "system with honeybadger" style = {{width: "40vmax"}} />
            
            <BasicText style = {{textAlign: "left"}} className = {"opensans" + " " + "t"}>

                Recall that the direct barrier to scalability and seamless contract 
                migration is the storage of indexed data, and in the case that it 
                cannot be easily retrieved, global data as well.  The clear commonality 
                between the two is that they are <i>data. </i>  One of the key ways 
                that HoneyBadger distinguishes itself from alternative solutions is 
                the fact that it can manage thousands of partitioned storage spaces, 
                each tracking a separate data interface.  These interfaces can be freely 
                extended over time (ie; adding the field "eye_color" to a "human" interface 
                containing: &#123;hair, height, weight&#125;).  To appreciate this fully, 
                consider that the standard alternative is to deploy a new storage system 
                instance that contains the desired fields, connect that to the existing 
                system, and invoke it in conjunction with the existing storage system 
                when retrieving user data.  Obviously, this standard practice 
                is quite convoluted and can rapidly increase the complexity and 
                operational costs associated with a smart contract system.  A 
                contract system with access to the (difficult to implement, and therefore rare)
                ability to extend existing data interfaces gains a distinct and 
                measurable advantage over competitors without any real drawbacks, free to fully 
                and efficiently accomodate new updates.

                <br/><br/>

                The significance of partitioned storage spaces is also quite considerable, 
                allowing developers to manage ALL of their ecosystem's data with a single, 
                highly-capable and optimized storage system, as opposed to 
                a network of independent layers associated with specific data, which 
                are likely to vary in terms of capability, efficiency, and security.  Additionally, 
                this opens the door to true pure-logic contracts, which are cheap to 
                deploy and highly-amenable to <i>delegatecall</i> operations due to the 
                clear storage space.  It should be noted that storing tons of data in a single 
                contract system does not introduce bloated gas costs: at a low-level, data is 
                not associated with a specific contract in the way that one would intuitively expect.
                  Instead, data is stored using a 
                Patricia-Merkel trie associated with the entire Ethereum network: your contract 
                address is simply used as an identifier within this trie to distinguish 
                data as belonging to that contract.  Hence, it really is not possible for a 
                smart contract to be "bloated," although it does seem logical at first glance 
                becuase <i>bytecode</i> can indeed be bloated on account of excessive contract logic,
                although this only leads to higher <i>deployment </i> costs, still not impacting 
                the cost of any particular operation.  

            </BasicText>

            <img src = {nbh} alt = "4-layer contract system without HoneyBadger" style = {{width: "40vmax"}}/>

            <BasicText style = {{ textAlign: "left"}} className = {"opensans" + " " + "t"}>

                Let's take a moment to really understand the significance of these two 
                synergistic features.  With HoneyBadger, we can define a set of 
                partitioned storage spaces and their associated data interfaces on 
                launch day.  At any time, we are free to create a new storage space and 
                populate it with a new interface.  We can also extend the interface associated with any existing 
                storage space.  In a traditional system, a storage space is most-often  managed by a 
                single contract, meaning that this pattern would require multiple independent 
                contracts which would need to be linked to the logical system.  If any of the 
                associated interfaces need to be extended, it will have to be in an additional 
                contract, which will need to be associated back with that original interface 
                as a logical abstraction.  It is confusing to even describe, and it is 
                even more cumbersome in production.  This common pattern leads to 
                high complexity and costs, and has zero distinct advantages over 
                what HoneyBadger offers.  Simply put, the common pattern demands
                 significantly more work, higher gas costs, 
                more integration, and a much higher likelihood of vulnerabilities 
                (which cannot be easily addressed if they are within one of the 
                storage system instances).  The only clear benefit is saving the 
                ~$2,000 cost of a HoneyBadger deployment license, which is 
                quite minor when contrasted with the massive associated increase in development hours.

                <br/><br/>

                It is also worth noting that HoneyBadger has comprehensive type support, 
                supporting uint(8...256), int(8...256), bool, address, bytes32, static string(1...32), 
                and dynamic string(0...+infinity).  For unsupported types like byte4, developers 
                can simply store the data in a uint256 (remember to pack it properly!) and 
                convert it into the desired type on retrieval.  Together, this small subset of 
                HoneyBadger's aggregate features make it nigh-universally applicable.  Any 
                contract system that stores data is fair game, able to gain a level of 
                scalability that has been scarcely-seen in the contemporary market, and 
                without sacrificing other key concerns such as security or efficiency.  

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                    Why Are HoneyBadger Functions Computationally-Inexpensive?
            </BasicHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                While we aren't going to cover exact implementation details or 
                reveal every trick up our sleeve, 
                especially since the efficiency of HoneyBadger operations 
                can be easily verified independently, it is worthwhile to 
                more clearly outline some of the elements contributing to this 
                central theme. 

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold",
            fontSize: "1.3vmax"}}>
                Low-Level Implementation
            </BasicHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                    HoneyBadger is almost entirely 
                    implemented in assembly.  When high-level Solidity code is 
                    deployed, it is first compiled into assembly (Yul), then 
                    the assembly code is compiled to bytecode.  The intermediate compilation 
                    from high-level code to Yul introduces additional overhead, such as 
                    type checks, overflow protection, array bounds checking, and 
                    memory management.  This is why many developers are frightened to use 
                    Yul in production: not only is the syntax unorthodox to those 
                    unfamiliar with functional programming, this medium explicitly introduces 
                    risks that are not present in high-level Solidity.  However, if a 
                    developer is fully aware of what the compilation step actually entails, 
                    then they can implement equivalent safety logic, but ONLY where it is necessary,
                    significantly reducing extraneous intermediate computations.  

                    <br/><br/>

                    This only-enough-for-equivalence approach is surely more complex, 
                    but it is also entirely feasible, and contrary to popular belief, not particularly risky.  
                    The mystification of Yul serves only to permanently hinder the efficiency of 
                    Solidity systems and discourage developers from challenging their own understanding of 
                    niche topics.  
                    It is factually advantageous to employ a slightly more complex implementation 
                    when the benefit is permanently-reduced transaction costs, without 
                    sacrificing any security or usability.  
                    
                    <br/><br/>

                    How is security equivalent in the 
                    absence of these automatic, quite often redundant checks?  
                    Imagine if for every mile you drove, you had to pull over and 
                    show police your license and vehicle registration.  Certainly, it is 
                    more efficient to prove that only when necessary, such as 
                    under the condition that you have committed a traffic infraction.  
                    Correspondingly, HoneyBadger includes the same checks as the typical compilation process, but differs 
                    in that it doesn't redundantly verify them.  This design choice directly correlates 
                    to one of our key tenets: if this universal system is to be timeless, it should 
                    explore every possible avenue of improvement to their consummation, regardless 
                    of added implementation difficulty.  The more costly and difficult the system is to replicate, the more valuable it is,
                    so long as that difficulty is concretely beneficial.

                    <br/><br/>

                    Another important benefit that Yul provides is much deeper 
                    low-level control than high-level Solidity.  This added control 
                    is precisely what allows HoneyBadger to bundle such a wide range of complex features 
                    into a mutually-synergistic structure.  As we mentioned in "Setting the Stage," 
                    the ability to extend indexed data interfaces (regardless of whether it is populated) after deployment requires direct 
                    management of the storage space. This is only possible with Yul, and necessitates 
                    creating a custom hashing system, among other requirements.  On top of this, the data that is 
                    stored in the system should be compatible with typical Solidity types, which necessitates 
                    the development of type abstractions and creating a versatile data packing system.  
                    HoneyBadger uses a proprietary solution to maximize the efficiency of this complex, 
                    yet mutually-reinforcing structure.  Our solution plays a particular role in the data packing and retrieval procedure 
                    utilized in key functions such as <i>put </i> and <i>get,</i> and is only 
                    possible due to the use of Yul.  The control required to achieve true 
                    universality through features such as full type support, segregated (and extendable) storage spaces, 
                    and extendable data interfaces is largely exclusive to an assembly implementation.  

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold",
            fontSize: "1.3vmax"}}>
                    Logical Optimization 
            </BasicHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                Another key factor contributing to the efficiency of HoneyBadger's operations 
                is a deep emphasis on logical optimization within the context of 
                its assembly-heavy implementation.  Let's focus on the key factors that significantly impact gas costs, rather than specific techniques. Firstly, 
                we know that any Solidity contract system is really just a combination of 
                low-level opcodes, as proven by the high-level to assembly compilation step.  
                Of these opcodes, modifying and retrieving values from the storage space 
                introduce outsized costs.  When storing data to a fresh, un-populated slot, the cost 
                is 20,000 gas, while storing to a populated slot costs 5,000 gas.  As a benchmark, 
                dividing two values costs 5 gas: 1,000 <i>div(a, b)</i> calls is equivalent to 
                invoking the cheapest <i>sstore</i> operation a single time, and <i>div</i> is 
                more computationally-expensive than other basic operations like addition, subtraction, and multiplication.  
                
                <br/><br/>

                But how can HoneyBadger reduce the costs incurred by these expensive storage operations? 
                The answer requires some understanding of how data is stored in general.  As you may know, 
                Solidity storage slots occupy 256 bits of space.  However, not all values take a full 256 bits.  
                For instance, integer values can be 8, 16, 32, 128, or 256 bits.  Hence, we could store 
                32 of the smallest (8-bit) integer values within a single slot.  This is why batch storage 
                is so useful: if we store 32 8-bit values with batch storage, it will only require a single 
                <i> sstore,</i> costing 5,000 gas instead of 160,000, or 20,000 instead of 640,000 in the 
                case that the slot(s) are not populated.  While this is the best-case scenario, 
                even removing a single <i>sstore</i> can have a significant effect.
                This is because packed slots greatly reduce the frequency of the 20,000 gas <i> sstore </i> 
                operation required to populate a brand new slot.  Critically, new users will invariably incur these 
                higher costs when they start interacting with a contract system that stores user-specific data,
                as they won't have any pre-populated data associated with their user id.
                The greater this immediate gas penalty is, the higher the likelihood that users will 
                choose not to use the system.  This is compounded by the fact that many users will 
                not be aware of this first-store penalty, and will simply assume that the contract system is 
                expensive to use.

                <br/><br/>

                This brings to light the second element of HoneyBadger's optimization: 
                developers can configure HoneyBadger around storage patterns within their 
                contract system to maximize savings.  For instance, if we know that 
                four 64-bit values are modified in close succession, we can group these 
                values into a single slot when configuring the relevant storage space (that is, when invoking 
                <i> init</i> or even <i>insert_new_member</i>).  If we know that these values are in 
                the same slot, we can use batch storage with maximum efficiency, removing three 
                <i> sstore</i> operations while retaining logical equivalence to the more 
                typical pattern of simply modifying each value in succession.  Given that developers 
                do not need to deploy their HoneyBadger instance until they have fully implemented 
                their system, it is easy to identify such opportunities and configure the storage 
                space accordingly.  

                <br/><br/>

                HoneyBadger also provides ways to optimize single-value storage and retrieval.  
                Accessing a packed value requires extracting that section from its slot.  
                For instance, a slot could be configured as:
                <br/><br/>
                 [ 64 bits | 64 bits | 128 bits ].
                 <br/><br/>
                If we want to modify the middle value, we would need to isolate it, modify 
                its value, remove that section from the slot, then insert the new value 
                into the slot. However, since a 256-bit value occupies an entire slot, it simply needs to be replaced.
                Correspondingly, HoneyBadger provides 
                streamlined <i>put256 </i> and <i>get256</i> functions, which remove 
                these extraneous intermediate calculations, reducing gas costs.   Notably, 
                256-bit values are still completely compatible with basic <i>put </i> and 
                <i> get</i> functions.

                <br/><br/>

                One of the most unique storage features offered by the HoneyBadger system is called 
                <i> two-step-put</i>.  These functions leverage the fact that <i>view</i> functions 
                incur no gas costs to resolutely minimize the cost of storage.  
                The caller first invokes <i>stage1_put, </i> which performs all intermediate 
                calculations and returns (hash, bitmap page, packedValue).  The caller 
                can then invoke <i>stage2_put(hash, page, packedValue)</i>, which uses this data to directly insert 
                the desired value into the system, rendering all intermediate 
                calculations free, while isolating the gas-incurring element of the 
                operation to only the unavoidable <i>sstore.</i>  Two-step-put reaches 
                the absolute minimum cost for storage, but should be used 
                with caution: the data returned from stage 1 can be modified, 
                and it is impossible for its integrity to be verified by stage 2 
                without incurring significant added costs, as we would need to store 
                a reference to the correct data: in storage.  Hence, this 
                function should either be disabled entirely or restricted to only approved parties.
                  Notably, this function 
                is not included in security-focused implementations such as 
                HoneyBadgerDAO.  However, given that it does provide a concrete and significant advantage and 
                can conceivably be used responsibly, it is included in models that carry a reduced emphasis on 
                concretely ruling out foul play, such as the standard HoneyBadger and HoneyBadgerLong models.

                <br/><br/>

                If you are interested in using two-step-put, here is our suggested approach.  
                The function should only be callable by an approved smart contract, and 
                should be invoked from a Web3-integrated frontend.  Users invoke the first stage, 
                and the resulting data is stored in fast-retrieval, temporary cloud storage.  
                When the user executes the second stage, the input should be verified against 
                the cloud-stored data using an oracle.  

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                Understanding Why HoneyBadger is so Secure
            </BasicHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                As we have outlined in previous sections, concretely-guaranteed 
                security is a non-negotiable priority.  To understand 
                how HoneyBadger makes contract systems more secure, we should first identify how 
                smart contract systems can fall prey to malicious actors in the first place.  


            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold",
            fontSize: "1.3vmax"}}>
                    External Actors
            </BasicHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                Let's first consider how an outsider with no administrative affiliations or permissions 
                could attack 
                a smart contract system.  To breach the system, they need to be able to 
                invoke its functions.  If a function imposes a strict permission gate, 
                ie: require(permissionLevel[msg.sender] &gt; 0), it is simply impossible for 
                them to invoke this function unless they have those permissions.  We can refer 
                to this as a <i>hard barrier</i>: it is completely impossible for this function to be 
                invoked without the requisite permissions.  Consequently, attackers have two choices.  
                They can find vulnerabilities within user-level functions (ie; no permission gate), 
                or they can try to gain the necessary permissions.  

                <br/><br/>

                How can an attacker exploit a user-level function?  Typically, this is accomplished 
                by leveraging vulnerabilities they have discovered in the smart contract code, which can be 
                exploited with certain function input.  Hence, it is important to constrain 
                input to expected values whenever possible, using <i>require</i> statements.  Luckily, 
                Solidity has introduced additional checks in the High-Level-Solidity to Yul compilation step, 
                such as overflow and array bounds checking, which render this type of attack significantly more 
                difficult.  However, this does not rule out the possibility of logical errors, which is 
                why smart contract systems require extensive testing.  

                <br/><br/>

                The second case can be significantly harder to prevent.  The most obvious route of 
                attack is through <i>social engineering, </i> where an attacker shamelessly 
                manipulates individuals into giving up sensitive information, or more pointedly, 
                to explicitly granting their address with permissions.  However, this is not particularly 
                likely to succeed: why would an administrator hand out permissions to some random 
                individual, just based on their claims?  The more likely avenue of attack is 
                by gaining access to administrator hardware, such as their personal desktop.  In this case, 
                the attacker can download malicious programs such as <i>keyloggers, </i> allowing 
                them to gather enough sensitive information to gain access to administrator wallets.  

                <br/><br/>

                The common thread between these attack vectors is that HoneyBadger can do very little to 
                prevent them: the burden of responsibility rests not on peripheral infrastructure, but administrators 
                and developers themselves.  <b>But how do we know that HoneyBadger is logically-sound? </b>  
                HoneyBadger has undergone extensive testing and fuzzing, along with several rounds of auditing.  
                While the system is complex, its implementation is also largely uniform: for instance, 
                variants of the <i>put </i> function vary (ie; put, put256, put_batch), in terms of their 
                specified use case, but are fundamentally very similar.  Furthermore, the system is extremely streamlined, 
                containing no extraneous code or mystified implementation methods.  HoneyBadger also imposes all necessary checks 
                included in the HLS-to-Yul compilation step, 
                 and notably can only 
                be directly invoked by permission-bearing administrators, meaning that attackers cannot influence the system 
                directly, but most go through an additional layer of logic and security checks 
                custom-built by the respective development team.  Consequently, external actors 
                who succeed will do so by exploiting logical issues created by developers USING HoneyBadger, 
                not within the system itself.  
                

                </BasicText>

                <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold",
            fontSize: "1.3vmax"}}>
                Malicious Administrators
            </BasicHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                The biggest security gap that any smart contract system can have is within the realm of 
                unmetered administrator power.  As we have explored in previous sections, this is 
                almost always exacerbated by scalability solutions, as they introduce 
                low-level control that factually undermines the "contract" element of smart 
                contract systems.  

                <br/><br/>

                Luckily, HoneyBadger is far from a typical scalability solution.  While it is 
                true that these advanced capabilities must exist, they do not necessarily 
                need to be left open to administrators.  Before we get into specifics, note 
                that there are numerous HoneyBadger implementations, varying widely 
                in terms of security.  For instance, the default HoneyBadger model is 
                moderately lax, allowing user-addresses to hold heightened permissions.  
                However, even in this model, users can freely check whether these 
                permissions are held at all, as well as the specific addresses who 
                hold them, making it easy to discern whether a contract system is truly safe.  
                The reason why the default model does not impose super-strict security 
                measures is because they add extra configuration steps, can be renounced (permanently disables 
                the possibility of user-accounts holding permissions), and are not uniformly-necessary.  

                <br/><br/>

                Before we talk about the most secure model (HoneyBadger DAO), which minimizes  
                the possibility of malicious administrative action, let's take a moment to outline 
                some of the common features that make any HoneyBadger model implicitly quite secure 
                on this front.  Firstly, HoneyBadger includes a <i>verify_integrity()</i> function, 
                which can be invoked at a user-level, and cannot be disabled.  This function 
                describes the full permissions layout, including the addresses that hold 
                permissions, and whether they are smart-contract or user-addresses.  The system 
                also encourages administrators to open-source any smart contract with permissions.  If 
                a contract is not open-sourced, the system will not pass verify_integrity(), unless 
                a bogus link is input, which can be quickly verified by users.  The logic behind this is 
                that users should be able to get a clear picture as to the concrete safety of 
                the system that they are using, but it is not our place to tell developers how to use 
                HoneyBadger.  In the case that only smart contracts hold permissions, and these 
                contracts are open-sourced, users can concretely verify whether there are outlets 
                for malicious action.  If these outlets are identified, it is likely to 
                severely damage the reputation of that project, which creates a strong 
                incentive for transparency.  

                <br/><br/>

                HoneyBadger DAO ramps security up to the maximum level.  Administrators 
                can freely configure the system on initialization, but are unable to hold 
                direct authority over the system.  Instead, only smart contract 
                addresses can hold permissions.  When administrators want to make changes, 
                they create a <i>governance proposal </i> to be verified by the 
                community before it is autonomously enacted.  This way, power can be 
                concretely delegated to the community, but without sacrificing any 
                capabilities.  

                <br/><br/>

                HoneyBadger DAO also imposes function-level permissions: instead of 
                uniform permission levels, smart contracts must be given explicit 
                permissions to invoke any function.  Futhermore, the system includes 
                private members, which are dataset members that can only be 
                accessed by contracts with -- you guessed it -- explicit permissions.  
                These measures synergize with standard-model security layers to 
                create a transparent and concretely-secure system with enhanced 
                capabilities and full user autonomy.  

                <br/><br/>

                In conclusion, HoneyBadger pushes security far beyond established 
                practices, but without sacrificing in terms of capabilities or 
                forcing administrators to conform to unnecessary or rigid 
                protocols.  It is absolutely impossible to fully rule out the 
                possibility of the system being employed in a malicious project, 
                which is why we take clear steps to empower common users 
                with the ability to discern the true security picture 
                of projects using the system.  By combining these measures, we 
                maintain the concrete integrity provided by rigid smart contract systems, 
                while providing greatly-enhanced capabilities that enable 
                continued innovation, extreme optimization, and vulnerability protection, 
                to name a few benefits.  

            </BasicText>


        </PageWrapper>
    )
}