import styles from './ContentSection1.module.css';
import common from '../common.module.css'
import '../../../../css/keyFonts.css';
import '../../../../css/TextStyles.css';

export default function ContentSection1()
{
    return(
        <div className = {styles.ContainerSection1}>

            <div className = {styles.TopTextContainer}>
                <p className={styles.TopText}>
                <span className = {styles.Icon}>⚡</span>GENESIS TOKEN LAUNCHING FEBRUARY</p>
                <p  className={styles.TopText}><span
                style = {{marginLeft: "4px"}} className = {`ShinyText ${styles.Icon}`}>
                ⧫</span> &nbsp;ERC-7844 STATUS: Draft</p>
            </div>


            <div className = {styles.LeftAlign}>
                <h2 className = {`TrapBold ${styles.Header}`}>HONEYBADGER FRAMEWORK</h2>

                <p className={common.Sub} style = {{marginTop: "10px"}}> 
                    
                    Solidity infra enabling advanced interoperability, 
                    streamlined development, and unparalleled storage fluidity 
                    that doesn't require redeployments.
                </p>

                
                <button className = {`${styles.UnderButton} HN`}>
                    ERC-7844: Ethereum Magicians
                </button>

                <hr className = {styles.DividerLine} />

                <h2 className = "TrapBold" style = {{color: 'white'}}>
                    <span className = "ShinyText">Cutting-Edge</span> is the New Standard.
                </h2>
                
                <ul className = {`${common.Sub} ${common.ListStyle} HN`}>
                    <li>Cheaper audits</li>
                    <li>Streamlined development</li>
                    <li>In-place storage extendibility</li>
                    <li>Extreme low-level optimization</li>
                    <li>Batch storage, governance, permission management, modules...</li>
                </ul>
                <i className = {`${common.Sub} ${styles.LowerTextMargin} HN`}>
                The best part?  HoneyBadger is <span 
                style = {{color: '#3e9c35'}}>FREE</span>.  And it's easy to use.</i>

            </div>
        </div>
    )
}