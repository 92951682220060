import { H, H2, Li, P, S, Sm, Tab, Ul, White } from "../Common/CommonElements";
import PageLayout from "../Common/PageLayout";
import styles from '../Common/page.module.css';
import pageStyles from '../Common/page.module.css';
import GenesisImg from '../../../../assets/GenesisImg.svg';
import Governance from '../../../../assets/Governance.svg';

export default function Page1_3({mobileMode})
{
    return(
        <PageLayout mobileMode={mobileMode}>
            <h2 className={styles.PageHeader}>Introduction</h2>
            <P>
                <Tab />
                The HoneyBadger Genesis Token ($HBGEN) serves as the foundation for the 
                initial phase of the HoneyBadger ecosystem. As the precursor to the main $HB 
                token, $HBGEN is designed to empower early adopters, incentivize contributors, 
                and establish governance mechanisms. Beyond its governance utility, $HBGEN 
                unlocks exclusive benefits, such as access to one-of-a-kind NFTs and participation 
                in a VIP Telegram group. These features provide early adopters with lasting 
                advantages while enabling the HoneyBadger ecosystem to operate effectively 
                during its pre-launch phase.
                <br/><br/>

                <Tab /> By introducing $HBGEN, the HoneyBadger ecosystem accelerates its 
                ability to engage its community, reward contributors, and prepare for the 
                full release of the framework and main $HB token. This strategic launch 
                ensures that the ecosystem evolves with thorough audits, robust governance, 
                and meaningful early participation.
            </P>

            <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />
            <div className={pageStyles.Center} >
                <img className = {pageStyles.Img} src = {GenesisImg} />
            </div>
            <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />

            <S />
            <H>Purpose of the Genesis Token</H>
            <P>
            The primary purpose of the Genesis Token is to bootstrap governance, 
            reward early contributors, and fund the open-source development and 
            auditing of the HoneyBadger framework. The Genesis Token launch enables 
            the ecosystem to operate with:
            </P>
            <Ul>
                <Li>Early Governance</Li>
                <Sm>$HBGEN is the sole governance token during the pre-launch phase, 
                allowing contributors to shape the future of the ecosystem while 
                earning additional rewards for participation.
                </Sm>   
                <S />
                <Li>Amplifying Contributors and Early Adopters</Li>
                <Sm>
                    $HBGEN rewards provide engaged community members 
                    with a greater sway in governance.
                </Sm>
            </Ul>
            <S />
            <H>Utility and Features</H>
            <P>The Genesis Token provides utility across several core functions:</P>
            <Ul>
                <Li>Governance</Li>
                <Sm>
                $HBGEN enables holders to participate in proposal voting during the 
                pre-launch phase. Only the HoneyBadger team can submit official proposals 
                at this stage, but all $HBGEN holders can cast votes and earn rewards for 
                governance participation.
                </Sm>
                <S />
                <Li>Exclusive NFTs</Li>
                <Sm>
                $HBGEN holders can acquire rare HoneyBadger NFTs that provide permanent 
                points—an advantage that no future NFTs will offer.
                </Sm>
                <S />
                <Li>Priority Access</Li>
                <Sm>
                Users who own at least one $HBGEN NFT gain entry into the VIP Telegram group, 
                providing direct access to the team and top community members.
                </Sm>
                <S />
                <Li>
                    Staking
                </Li>
                <Sm>
                $HBGEN can be staked to earn passive rewards, with the same mechanics as the 
                main $HB token (10% in/out fees distributed to stakers).
                </Sm>
                <S />
                <Li>
                    Burn for Points
                </Li>
                <Sm>
                Holders of $HBGEN have the unique opportunity to burn their tokens in exchange 
                for permanent points. This mechanism allows users to solidify their long-term 
                stake in the ecosystem by increasing their baseline $HB allocation in future 
                token distributions. Permanent points are rare and offer enduring benefits, 
                making this feature a powerful incentive for those looking to maximize their 
                influence and rewards within the HoneyBadger ecosystem.
                </Sm>
            </Ul>

            <S />
            <H2>Distribution and Allocation</H2>
            <P>
                The distribution model for $HBGEN ensures fairness and transparency. 
            </P>
            <Ul>
                <Li>
                    Total Supply
                </Li>
                <Sm>
                The total supply of $HBGEN is fixed at 500,000 tokens.
                </Sm>
                <S />
                <Li>
                    Initial Airdrop
                </Li>
                <Sm>
                Early adopters who engage with the HoneyBadger Intract campaign 
                will receive airdropped tokens via a Merkle airdrop, subject to a 
                60-day vesting period.
                </Sm>
                <S />

                <Li>
                    Pre-Sale and Exchange Listing 
                </Li>
                <Sm>
                After the airdrop, $HBGEN will become available through a launchpad pre-sale 
                and later listed on exchanges. Tokens will not be transferable until 
                after the pre-sale.
                </Sm>

                <S />
                <Li>
                Burn Mechanics:
                </Li>
                <Sm>
                $HBGEN tokens used to purchase NFTs are burned, 
                contributing to the deflationary mechanics of the ecosystem.
                </Sm>
            </Ul>

            <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />
            <div className={pageStyles.Center} >
                <img className = {pageStyles.Img} src = {Governance} />
            </div>
            <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />

            <S/>
            <H2>
                Governance Model
            </H2>
            <P>Governance during the pre-launch phase is entirely powered by $HBGEN:</P>

            <Ul>
                <Li>Vote NFTs</Li>
                <Sm>
                    Governance uses a token-locking model 
                    where $HBGEN holders mint Vote NFTs to participate in proposals.
                </Sm>
                <S />

                <Li>Participation Rewards</Li>
                <Sm>
                    $HBGEN goverance can earn participants $HBGEN and 
                    points.
                </Sm>
            </Ul>

            <S />

            <H2>Points System Integration</H2>
            <P>
            $HBGEN plays a key role in the points-based rewards system, 
            incentivizing early contributors and engagement:
            </P>

            <Ul>
                <Li>Permanent Points</Li>
                <Sm>
                Actions such as holding Genesis-exclusive NFTs, verifying deployments, 
                or participating in governance can earn permanent points that increase 
                the user’s long-term allocation of $HB.
                </Sm>
                <S />

                <Li>
                    Temporary Points
                </Li>
                <Sm>
                Short-term actions, such as voting in a specific governance proposal, 
                earn temporary points that boost the user’s allocation for the next token 
                distribution event. 
                </Sm>
                <S />

                <Li>Deflationary $HB Distribution</Li>
                <Sm>
                    Future $HB distribution events allocate tokens based entirely 
                    on points earned by contributors.
                </Sm>
            </Ul>

            <S />
            <H2>Exclusive NFTs and Long-Term Advantages</H2>
            <P>
                The Genesis Token unlocks access to limited-edition HoneyBadger NFTs, providing benefits that will never be replicated in future drops. 
                These exclusive NFTs are designed to reward early adopters and offer long-term advantages that set them apart from future NFT releases:
            </P>
            <Ul>
                <Li>Permanent Points Advantage</Li>
                <Sm>
                    These NFTs are the only ones in the ecosystem that grant permanent points for as long as the holder retains them, 
                    giving early adopters a lasting edge in the HoneyBadger rewards system.
                </Sm>
                <S />
                <Li>Fixed Quantity</Li>
                <Sm>
                    The Genesis NFT collection will only be released once, ensuring exclusivity and scarcity. Once sold, no additional NFTs of 
                    this type will be minted.
                </Sm>
                <S />
                <Li>Additional Benefits</Li>
                <Sm>
                    Holding Genesis NFTs grants access to the VIP Telegram group, providing direct communication with the HoneyBadger team 
                    and top community members. These NFTs also establish the holder as an early supporter of the ecosystem.
                </Sm>
                <S />
                <Li>Comparison to Future NFTs</Li>
                <Sm>
                    While future NFTs in the HoneyBadger ecosystem will offer time-limited benefits, Genesis NFTs provide permanent rewards, 
                    creating significant long-term advantages for early adopters.
                </Sm>
            </Ul>

            <S />
            <H2>Staking and Passive Rewards</H2>
            <P>
                Genesis Token holders can stake their $HBGEN to earn passive rewards while contributing to the network’s growth and stability. 
                This staking mechanism is designed to align incentives, reduce circulating supply, and encourage long-term holding while offering 
                ongoing benefits to participants.
            </P>
            <Ul>
                <Li>Staking Mechanics</Li>
                <Sm>
                    A 10% fee is applied to both staking and unstaking transactions:
                    <Ul>
                        <Li>95% of the fees are distributed proportionally to all existing stakers as rewards.</Li>
                        <Li>4% of the fees are burned, contributing to the deflationary tokenomics of the system.</Li>
                        <Li>1% of the fees are allocated to the HoneyBadger team for continued ecosystem development.</Li>
                    </Ul>
                </Sm>
                <S />
                <Li>Reward Calculation</Li>
                <Sm>
                    Rewards are distributed based on the proportion of $HBGEN staked by each user relative to the total staked supply. The more 
                    tokens staked, the larger the share of rewards earned.
                </Sm>
                <S />
                <Li>Benefits of Staking</Li>
                <Sm>
                    Staking reduces token supply in circulation, stabilizes the ecosystem, and provides consistent rewards for long-term participants. 
                    It creates an ongoing incentive for holders to remain engaged with the ecosystem.
                </Sm>
            </Ul>

            <S />
            <H2>Transition to $HB</H2>
            <P>
                Once the HoneyBadger framework is fully launched, the Genesis Token ($HBGEN) will transition seamlessly into the main $HB token. 
                This ensures that early contributors retain their value and contributions as the ecosystem expands and matures.
            </P>
            <Ul>
                <Li>1-to-1 Conversion</Li>
                <Sm>
                    $HBGEN will be converted to $HB at a 1-to-1 ratio, preserving the holdings and rewards of early participants. This straightforward 
                    conversion ensures no loss of value for token holders.
                </Sm>
                <S />
                <Li>Governance Transition</Li>
                <Sm>
                    Governance will fully transition to the main $HB token upon launch, concluding the Genesis governance phase. This ensures a 
                    smooth handoff to the broader HoneyBadger community.
                </Sm>
                <S />
                <Li>Seamless Integration</Li>
                <Sm>
                    Genesis Token holders will retain their contributions, permanent points, and rewards as they transition into the wider HoneyBadger 
                    ecosystem, ensuring continuity and recognition for their early support.
                </Sm>
            </Ul>
            
            <S />
            <H2>Technical and Security Details</H2>
            <P>
                The Genesis Token ($HBGEN) is designed with security, transparency, and stability in mind. Key technical and security aspects include:
            </P>
            <Ul>
                <Li>Blockchain</Li>
                <Sm>
                    $HBGEN will be deployed on the Ethereum blockchain, leveraging its robust security and compatibility with the broader Web3 ecosystem.
                </Sm>
                <S />
                <Li>Merkle Airdrop</Li>
                <Sm>
                    Tokens distributed via airdrop will use a Merkle-proof system, ensuring transparency and security in token distribution. 
                    This system allows users to verify their allocations independently.
                </Sm>
                <S />
                <Li>Vesting Period</Li>
                <Sm>
                    Airdropped tokens will be subject to a 60-day vesting period to prevent immediate selling, stabilize the token’s value, and 
                    encourage sustained engagement within the ecosystem.
                </Sm>
                <S />
                <Li>Audits and Reviews</Li>
                <Sm>
                    The smart contract for $HBGEN will undergo thorough audits to ensure security and reliability, safeguarding user assets and 
                    maintaining trust within the community.
                </Sm>
            </Ul>
        </PageLayout>
    )
}