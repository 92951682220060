import '../../../../css/keyFonts.css';
import pageStyles from '../Common/page.module.css';
import styles from '../Common/page.module.css';
import { isMobile } from 'react-device-detect';
import PageLayout from '../Common/PageLayout';
import {P, H, Tab, Ul, Li, Sm, BigLi, H2, S, White, P2} from '../Common/CommonElements'
import Adaptability from '../../../../assets/Adaptability.svg';
import AdaptabilityExample from '../../../../assets/AdaptabilityExample.svg';
import Interoperability from '../../../../assets/Interoperability.svg';
import InteroperabilityExample from '../../../../assets/Siloed.svg';
import CostSavings from '../../../../assets/CostSavings.svg';
import Capabilities from '../../../../assets/Capabilities.svg';
import Efficiency from '../../../../assets/HighEfficiency.svg';
import EaseOfUse from '../../../../assets/EaseOfUse.svg';
import inPractice from '../../../../assets/InPractice.svg';

export default function Page_1({mobileMode})
{
    return (
        <PageLayout mobileMode = {mobileMode}>
            <h2 className={styles.PageHeader}>Pioneering New Limits for Smart Contract 
            Scalability, Cost-Effectiveness, and Interoperability</h2>
            <P>
                <Tab />
                HoneyBadger is a storage and permission management system for 
                Solidity smart contract systems.  It allows developers to update storage structures without 
                re-deployments, and enables seamless contract interoperability by 
                establishing a shared data bank.  The system is highly-optimized, 
                and provides plug-and-play access to useful features including 
                governance, batch operations, and permission management.
            </P>
            <div
                className={pageStyles.IFrameContainer}
            >
                <iframe
                    style={{
                        width: "100%",
                        maxWidth: "560px",
                        height: "100%",
                        borderRadius: "10px",
                        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)",
                    }}
                    src="https://www.youtube.com/embed/LngHy8ixdvs?start=32"
                ></iframe>
                <P><i>Understand HoneyBadger in Minutes!</i></P>
            </div>

            <H>Demystifying HoneyBadger</H>

            <P>
                <Tab />
                HoneyBadger is a smart contract.  Developers deploy their own 
                HoneyBadger instance, which they fully control.  Then, they call 
                functions to initialize their system.  Initialization entails 
                defining storage structures and granting permissions to external 
                contracts, creating a two-way link.  With this foundation, 
                linked contracts can invoke external calls to HoneyBadger to 
                modify and retrieve data.
            </P>

            <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />
                <div className={pageStyles.Center} >
                    <img className = {pageStyles.Img} src = {inPractice} />
                </div>
            <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />

            <H>Key Benefits</H>

                <Ul>
                    <div >
                        <Li>
                            <H2>Adaptability</H2>
                        </Li>
                        <P2>
                            <Tab />By default, smart contract systems are rigid and unable to update storage 
                            structures and logic after deployment. This inflexibility makes it challenging for 
                            established systems to adapt to shifting market demands or incorporate new 
                            features. Traditional scalability solutions, such as Proxy-Delegate 
                            or the Diamond Pattern, require developers to write complex
                            custom logic, redeploy contracts, and field expensive audits to support even 
                            minor updates. This process often bloats contract architectures, increases 
                            maintenance complexity, and diverts valuable resources away from innovation.
                            <br /><br/>
                            <Tab />HoneyBadger eliminates these challenges by introducing seamless storage 
                            adaptability through simple function calls. Instead of redeploying contracts 
                            or modifying core architecture, developers can modify or create new storage 
                            structures in place, all without impacting the functionality of existing 
                            systems. By leveraging the HoneyBadger framework, teams can efficiently 
                            implement dynamic upgrades, ensuring that systems remain flexible and 
                            responsive to changing requirements.
                            <br/><br/>
                            <Tab />This enhanced adaptability streamlines development and operational 
                            workflows, saving significant time and resources. With the ability to 
                            manage updates without re-deployments, teams avoid the high costs and 
                            risks typically associated with audits and migrations. Developers can 
                            focus on building innovative business logic instead of wrestling with the 
                            limitations of static storage. Furthermore, HoneyBadger’s forward-looking 
                            design, formalized through ERC-7844, ensures that systems remain 
                            interoperable and prepared for emerging market demands, keeping companies 
                            agile and competitive.
                                

                            <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />
                                <div className={pageStyles.Center} >
                                    <img className = {pageStyles.Img} src = {AdaptabilityExample} />
                                </div>
                            <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />

                            <S />
                                <b><White>Example Use Case:</White> Upgrading a DeFi Lending Platform</b>
                            
                            <P2>
                                <Tab />Lendify, a DeFi lending platform, wants to upgrade its smart contracts to support new 
                                collateral types, such as NFTs, and integrate with an automated liquidation protocol 
                                to stay competitive. However, their current system uses fixed storage structures, 
                                meaning any upgrade would require redeploying contracts, migrating user assets, 
                                and undergoing costly audits—all time-consuming and expensive.
                                <br/><br/>
                                <Tab />By using HoneyBadger, Lendify eliminates these issues entirely. Instead of redeploying 
                                contracts, they update their storage structure with a single function call, adding support 
                                for NFTs in minutes. HoneyBadger’s storage fluidity, powered by ERC-7844, also enables 
                                seamless integration with the new liquidation protocol without adding layers of complex logic. 
                                With no need for contract migrations or lengthy audits, Lendify is able to build and ship 
                                the upgrade quickly.
                            </P2>
                            
                        </P2>
                        <>
                            <div style = {{height: (mobileMode === true) ? "40px" : '40px'}} />
                            <div className={pageStyles.Center} >
                                <img className = {pageStyles.Img} src = {Adaptability} />
                            </div>
                            <div style = {{height: (mobileMode === true) ? "40px" : '40px'}} />
                        </>
                    </div>
                    <Li>
                        <H2>Interoperability</H2>
                    </Li>
                    <P2>
                    <Tab />Smart contract systems are inherently rigid and siloed, with each contract 
                        managing its own unique storage. Once deployed, this immutability makes seamless 
                        interaction between contracts difficult. For new logic to interface with pre-existing 
                        systems, developers write custom adapters, redeploy contracts, or migrate
                         data—processes that introduce bloated code, increase the attack surface, and drive 
                         up costs. Without upfront provisioning for interoperability, systems are left 
                         fragmented and unable to adapt to evolving multi-contract ecosystems.
                        <br/><br/>
                         <Tab />HoneyBadger rethinks interoperability by introducing a unified storage 
                        layer that allows smart contracts to share and interact with data across 
                        systems without the need for redeployments or adapters. Its native permissioning 
                        system ensures secure and controlled access to shared storage, allowing both new and 
                        existing logic to interact dynamically. By standardizing data sharing and removing the 
                        complexity of building custom integration layers, HoneyBadger enables truly frictionless 
                        cross-contract communication.
                        <br/><br/>
                        <Tab />This unified approach delivers substantial benefits: systems remain scalable, 
                        collaborative, and efficient while minimizing costs and reducing security risks. 
                        HoneyBadger opens the door to fully interoperable ecosystems, where contracts can 
                        seamlessly evolve and interconnect without architectural constraints.

                        <div style = {{height: (mobileMode === true) ? "40px" : '40px'}} />
                            <div className={pageStyles.Center} >
                                <img className = {pageStyles.Img} src = {InteroperabilityExample} />
                            </div>
                            <div style = {{height: (mobileMode === true) ? "40px" : '40px'}} />

                        <Ul>
                            <S />
                            <Li><b>Unified Storage Layer for Seamless Interaction:</b></Li>
                            <P>
                            Contracts leverage a single storage structure, enabling efficient 
                            data sharing and eliminating the need for custom adapters. This 
                            ensures interoperability across both new and legacy systems.
                            </P>
                            <S />
                            <Li><b>Native Permissioning for Controlled Access:</b></Li>
                            <P>
                            HoneyBadger's robust permissioning framework ensures only authorized 
                            contracts and entities can access or modify shared storage, protecting 
                            sensitive data and reducing the risk of vulnerabilities.
                            </P>
                            <S />
                            <Li><b>Multi-Contract Collaboration Without Redeployment:</b></Li>
                            <P>
                            HoneyBadger enables multiple smart contracts to collaborate and operate as 
                            a cohesive system, removing the need to redeploy or re-architect individual 
                            contracts when integrating new logic.
                            </P>
                            <S />
                            <Li><b>Simplified Integration for New Logic:</b></Li>
                            <P>
                            Developers can integrate new features or external systems into existing 
                            architectures without writing additional middleware or incurring downtime, 
                            allowing faster development cycles.
                            </P>
                        </Ul>

                    </P2>
                    <>
                        <div style = {{height: (mobileMode === true) ? "40px" : '40px'}} />
                        <div className={pageStyles.Center}>
                            <img 
                                src = {Interoperability} 
                                className = {pageStyles.Img}
                            />
                        </div>
                        <div style = {{height: (mobileMode === true) ? "40px" : '40px'}} />
                    </>
                    <BigLi>
                        <H2>Cost Reduction</H2>
                    </BigLi>
                    <P2>
                    <Tab />Smart contract development and maintenance are expensive. From the complexity of 
                    writing secure, efficient code to the hidden costs of audits, gas fees, and long-term 
                    maintenance, expenses can spiral throughout a system’s lifecycle. Addressing inefficiencies, 
                    fixing vulnerabilities, and managing the rigidity of traditional architectures often require 
                    costly redeployments and extensive testing, consuming valuable resources and slowing down 
                    innovation. These challenges make it difficult for teams to stay agile and competitive in 
                    fast-evolving markets.
                    <br/><br/>
                    <Tab />HoneyBadger solves these issues by providing a pre-built, highly optimized 
                    framework that eliminates the need for developers to build scalability and 
                    storage logic from scratch. With its robust infrastructure, HoneyBadger enables 
                    seamless upgrades, efficient storage management, and low-maintenance operations, 
                    allowing teams to focus on business logic instead of infrastructure headaches. 
                    By automating complex processes and streamlining development workflows, 
                    HoneyBadger significantly reduces the financial burden of building 
                    and maintaining smart contract systems.
                    <br/><br/>
                    <Tab />The benefits of HoneyBadger’s cost-saving architecture are substantial. 
                    Teams can launch projects faster, reduce ongoing operational expenses, and 
                    minimize the risks associated with vulnerabilities and inefficiencies. 
                    HoneyBadger's built-in optimizations ensure long-term scalability while 
                    keeping both development and user-facing costs low, enabling businesses 
                    to operate more effectively and scale confidently.
                    <Ul>
                            <S />
                        <Li><b>Eliminated Development Redundancies:</b></Li>
                        <P>
                        HoneyBadger's pre-audited architecture removes the need for teams to 
                        repeatedly develop and test foundational scalability or storage 
                        features for every project. This leads to faster launches with lower 
                        up-front investment.
                        </P>
                            <S />
                        <Li><b>Predictable Lifecycle Costs:</b></Li>
                        <P>
                        HoneyBadger’s modular and future-proofed framework ensures that upgrades 
                        and feature additions can be performed without significant new development or 
                        unexpected operational costs. This predictability enables teams to better manage 
                        budgets and avoid disruptive expenditures.
                        </P>
                            <S />
                        <Li><b>Lower Risk of System Downtime:</b></Li>
                        <P>
                        HoneyBadger minimizes downtime caused by redeployments or migrations, 
                        allowing businesses to avoid the indirect costs of lost user engagement, 
                        missed opportunities, and reputational damage.
                        </P>
                    </Ul>
                    </P2>
                    <S />
                    <>
                        <div style = {{height: (mobileMode === true) ? "40px" : '0px'}} />
                        <div className={pageStyles.Center}>
                            <img src = {CostSavings} className = {pageStyles.Img}/>
                        </div>
                        <div style = {{height: (mobileMode === true) ? "40px" : '0px'}} />
                    </>
                    <Li>
                        <H2>Powerful Capabilities</H2>
                    </Li>
                        

                        <Ul>
                            <Li>
                                <b>Functions</b>
                            </Li>
                            <P2>
                                    <Tab />HoneyBadger is equipped with advanced functionality that simplifies the 
                                development and scaling of smart contract systems. With pre-built tools for 
                                operations like batch processing, fine-grained permissioning, governance integration, 
                                and encrypted storage, HoneyBadger enables developers to focus on delivering innovative 
                                solutions without worrying about technical overhead. These capabilities ensure that systems 
                                remain efficient, secure, and adaptable as they grow, all while minimizing gas usage and 
                                operational complexity.
                                <br/><br/>
                        
                                <div style = {{ marginLeft: "40px", width: "80%"}}>
                                    <b>Batch Operations </b> 
                                    Easily process multiple values in a single transaction to reduce 
                                    gas costs and consolidate logic. 
                                    <br/>
                                    <S />

                                    <b>Optimized Put/Get </b> 
                                    Specialized 256-bit put/get operations deliver efficient 
                                    storage and retrieval.
                                    <br/>
                                    <S />
                                    <b>Dynamic Modules </b> HoneyBadger's module system allows new features to be 
                                    introduced in-place.
                                    <br/>
                                    <S />
                                    <b>Encryption Support </b> HoneyBadger is fully compatible with encrypted 
                                    data, and modules can introduce additional security layers.
                                    <br/>
                                    <S />
                                    <b>Specialized Implementations </b> Unique HoneyBadger models offer features including  
                                    proxy-delegate, streamlined deployment costs, and more restrictive decentralization.
                                </div>
                                
                            </P2>

                            <S />
                            <Li>
                            <b>Storage</b>
                            </Li>
                                <P2>
                                <Tab />The system's storage structures are robust and versatile, offering full type 
                                support, integer and address indexing, mapped arrays, and more. Designed 
                                to handle a wide range of use cases, HoneyBadger’s storage framework provides 
                                the flexibility and reliability needed to power advanced applications like DeFi 
                                protocols, DAOs, and blockchain-based games. 
                                <br/><br/>
                            <div style = {{ marginLeft: "40px", width: "80%"}}>

                                <b>Hot Insertion </b> Storage structures can be extended in-place.
                                <br/>
                                
                                <S />
                                <b>Dynamic Storage Structures </b> New storage structures can be created in-place.
                                <br/>
                                <S />
                                <b>No Collisions</b> HoneyBadger uses a robust hashing structure to prevent 
                                    storage collisions.
                                <br/>
                                <S />
                                <b>Full Type Support </b> HoneyBadger supports uint, int, bool, address, 
                                bytes32, and string. 
                                <br/>
                                <S />
                                <b>Arrays </b> The string type doubles as an array.
                                <br/>
                                <S />
                                <b>Address and Uint Indexing </b> HoneyBadger provides flexibility 
                                when it comes to storage addressing.
                            </div>
                                </P2>
                        </Ul>


                    <>
                        <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />
                        <div className={pageStyles.Center} >
                            <img className = {pageStyles.Img} src = {Capabilities} />
                        </div>
                        <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />
                    </>
                    <H2>
                        High Efficiency
                    </H2>
                    <P2>
                    <Tab />Smart contract systems often face significant efficiency challenges due to the 
                    high cost of executing operations on the blockchain. Gas fees can skyrocket when 
                    handling large datasets, performing repetitive tasks, or operating across 
                    multiple contracts, making scalability a costly and complex endeavor. 
                    Additionally, many scalability solutions rely on layered architectures or 
                    bloated contract interactions, which create inefficiencies, increase complexity, 
                    and hinder system performance.
                    <br/><br/>

                    <Tab />HoneyBadger addresses these challenges by employing low-level Solidity assembly 
                    optimizations, compact metadata structures, and streamlined storage management 
                    techniques. By focusing on efficient execution and minimizing resource usage, 
                    HoneyBadger reduces gas costs across the board while simplifying contract 
                    interactions. Unlike traditional solutions that rely on complex, multi-contract 
                    patterns, HoneyBadger operates as a single, highly optimized layer that 
                    minimizes the computational overhead associated with data storage and retrieval.
                    <br/><br/>
                    <Tab />This results in tangible benefits for developers and users alike. Companies 
                    deploying HoneyBadger can scale their applications without burdening users 
                    with high transaction fees, making their systems more accessible and competitive.
                     Developers benefit from reduced operational complexity, enabling them to create 
                     efficient and scalable systems without sacrificing performance or budget.
                        <div style = {{ marginLeft: "40px", width: "80%"}}>
                        <br/><br/>
                            <b>Assembly-Based Optimizations for Execution Efficiency </b> 
                            HoneyBadger uses low-level Solidity assembly to streamline 
                            core operations, minimizing gas costs for heavy computational tasks. 
                            This approach ensures faster and more efficient execution of critical 
                            functions.
                            <br/>
                            <S />
                            <b>Compact Metadata for Storage Savings</b> 
                            HoneyBadger implements metadata structures based on ERC-7844’s 
                            compact format, significantly reducing the size of stored data. 
                            This allows systems to manage larger datasets while keeping storage 
                            costs under control.
                            <br/>
                            <S />
                            <b>Single-Layer Storage Architecture </b> 
                            Unlike traditional "spiderweb" scalability solutions that rely on layered 
                            or fragmented contract structures, HoneyBadger consolidates functionality into a 
                            unified, efficient storage layer. This approach minimizes inter-contract complexity and 
                            optimizes resource usage.
                            <br />
                        </div>

                    </P2>
                    <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />
                        <div className={pageStyles.Center} >
                            <img className = {pageStyles.Img} src = {Efficiency} />
                        </div>
                    <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />
                    <H2>
                        Ease of Use
                    </H2>
                    <P>
                        <Tab />Despite fielding a complex implementation, HoneyBadger provides a 
                        straightforward developer experience that prioritizes familiar structures and 
                        simplified logic.  Developers deploy their own instance of the contract, 
                        then leverage simple function calls to configure storage and 
                        permissions.  Initialization is easy to automate, providing a consistent 
                        structure and the ability to define storage for many contracts at once.
                    </P>
                    <P2>
                        <div style = {{ marginLeft: "40px", width: "80%"}}>
                            <br />
                            <b>Simple Deployment Process </b> Deploying a HoneyBadger instance 
                            is intuitive and easy to automate, requiring only a few straightforward steps 
                            to initialize storage and permissions.  
                            <br/>
                            <S />
                            <b>Plug-and-Play Functionality </b> HoneyBadger offers a modular 
                            out-of-the-box solution that integartes seamlessly with existing logic.
                            <br/>
                            <S />
                            <b>Intuitive API Design </b> HoneyBadger's logical syntax is 
                            straightforward and easy to learn.
                            <br/>
                            <S />
                            <b>Familiar Solidity Patterns </b> The system aligns with common 
                            Solidity development practices.
                        </div>
                    </P2>
                    <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />
                    <div className={pageStyles.Center} >
                        <img className = {pageStyles.Img} src = {EaseOfUse} />
                    </div>
                    <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />
                </Ul>
                <S />

                <H>
                Long-Term Vision
                </H>
                <P>
                <Tab /> HoneyBadger is built to redefine how Solidity projects are developed, 
                scaled, and sustained over the long term. By combining advanced technical 
                capabilities with a thriving token ecosystem, HoneyBadger aims to provide a 
                complete package that empowers partners throughout the entire 
                Web3 development lifecycle. Below is a clear articulation of how HoneyBadger envisions 
                its long-term role in the Web3 space.
                </P>

                <S />
                <H2>
                    Optimizing the Solidity Development Lifecycle
                </H2>
                <P>
                <Tab />The HoneyBadger Framework makes full storage upgradeability and 
                cutting-edge interoperability easily obtainable. Through its streamlined 
                architecture, pre-built modules, and seamless scalability tools, 
                HoneyBadger reduces development complexity for developers, allowing them to focus on 
                building robust applications. The continued evolution of 
                these features will manifest a more 
                accessible and efficient development environment that benefits both new and 
                established projects.
                </P>
                <S />

                <H2>
                Sustainable Ecosystem Growth
                </H2>
                <P>
                <Tab />The HoneyBadger ecosystem is built for sustained growth by fostering 
                collaboration between developers, users, and third-party projects. 
                Key initiatives include:
                </P>
                <P2 >
                    <div style = {{ marginLeft: "40px", width: "80%",}}>
                        <br/>
                        <b>Developer Tools </b>
                        Specialized libraries, code extensions, and pre-built templates for common use cases.
                        <br/><S />

                        <b>Token Grants </b>
                        Supporting projects that integrate $HB or expand the utility of the framework.
                        <br/><S />

                        <b>Hackathons, Audits, Open-Source </b> The HoneyBadger token ecosystem rewards contributors 
                        with an amplified voice in the community.
                    </div>
                </P2>

        </PageLayout>
    )
}


