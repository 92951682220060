import { fragment, vertex } from "./shaders";
import { TextureLoader } from "three";
import { useFrame, useLoader } from "@react-three/fiber";
import background from '../../../assets/mobilebg.png';
import gsap from "gsap";
import { useEffect, useRef } from "react";

export default function MobileBg()
{

    useEffect(() => {
        if(!ref.current) return;

        // gsap.timeline({repeat: -1, yoyo: true}).to(
        //     ref.current.position, {x: 5, duration: 5, ease: "none"}
        // ).to(ref.current.position, {x: -5, duration: 5, ease: "none"})

    }, [])

    const ref = useRef();
    const image = useLoader(TextureLoader, background);

    return(
        <mesh position = {[0, 0, -20]} ref = {ref}>
            <meshBasicMaterial color = "black"/>
            <planeGeometry args = {[20, 40]}/>
        </mesh>
    )
}