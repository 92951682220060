import { useSpring, animated } from "react-spring"
import Page_1 from "./Page_1/Page_1"
import Page2_1 from "./Page_2/Page2_1"
import Page2_2 from "./Page_2/Page2_2"
import Page3_1 from "./Page_3/Page3_1"
import Page3_2 from "./Page_3/Page3_2"
import Page3_3 from "./Page_3/Page3_3"
import Page3_4 from "./Page_3/Page3_4"
import Page3_5 from "./Page_3/Page3_5"
import Page_5_1 from "./Page_5/Page_5_1"
import Page_5_2 from "./Page_5/Page_5_2"
import Page_1_2 from "./Page_1/Page_1_2"
import Page1_3 from "./Page_1/Page_1_3"
import Page1_4 from "./Page_1/Page_1_4"


export default function PageManager({mobileMode, page})
{
    if(page === 0 || page === undefined) return;

    if(page == 1.1) return <Page_1 mobileMode = {mobileMode} />
    if(page == 1.2) return <Page_1_2  mobileMode={mobileMode} />
    if(page == 1.3) return <Page1_3 mobileMode={mobileMode} />
    if(page == 1.4) return <Page1_4 mobileMode={mobileMode} />

    if(page == 2.1) return <Page2_1 />
    if(page == 2.2) return <Page2_2 />

    if(page == 3.1) return <Page3_1 />
    if(page == 3.2) return <Page3_2 />
    if(page == 3.3) return <Page3_3 />
    if(page == 3.4) return <Page3_4 />
    if(page == 3.5) return <Page3_5 />

    if(page == 5.1) return <Page_5_1 mobileMode = {mobileMode}/>
    if(page == 5.2) return <Page_5_2 mobileMode = {mobileMode}/>

}

export const PageWrapper = (props) =>
{
    let d = props.docked == "true" ? true : false;

    const anim = useSpring({

        from: {
            width: "67vmax",
            background: "#151515", 
            zIndex: 50, 
            position: "absolute", 
            marginLeft: "26.75vmax", 
            marginTop: "6vmax", 
            border: "1px solid #505050",
            opacity: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: 'center',
            textAlign: 'center',
            paddingBottom: "10vmax",
            color: "white"},
        to: {
            width: (d) ? "85.5vmax":"67vmax",  
            background: "#151515", 
            zIndex: 50, 
            position: "absolute", 
            marginLeft: (d) ? "10vmax" :"26.75vmax", 
            marginTop: "6vmax",
            border: "1px solid #505050",
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: 'center',
            alignItems: 'center',
            paddingBottom: "10vmax",
            borderRadius: "5px",
            color: "white"},
        config: {duration: 500},
        delay: 250
    });

    return(
        <>
            <animated.div style = {anim} {...props}>
                {props.children}
            </animated.div>
        </>
    )
}