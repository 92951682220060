import { PageWrapper } from "../PageManager";
import { BasicText, BasicHeading, BigHeading } from "../styledComponents";
import gsap from "gsap";
import { useEffect } from "react";

export default function Page3_5(props)
{

    useEffect(() =>
    {
        gsap.to(".t", {width: (props.docked == false) ? "50vmax" : "65vmax", opacity: 1})
    }, [props.docked])

    return(
        <PageWrapper docked = {props.docked == true ? "true" : "false"}>
            <BigHeading style = {{marginTop: "5vmax"}} className = {"inter" + " " + "fr" + " " + "t"}>
                Low-Level Architecture
            </BigHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left", fontSize: "1.2vmax"}} className = {"opensans" + " " + "t"}>

                This section outlines important elements of the HoneyBadger architecture 
                relevant to developers who plan to use the system in production.

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                Global Variable Layout
            </BasicHeading>


            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                HoneyBadger is relatively sparse about slot(0), tracking the following globals 
                in the default model:     
                <br/>
                <br/>• address private _owner;
                <br/>
                <br/>• bool public delegationEnabled;
                <br/>• bool public initialized;
                <br/>• bool public verified;
                <br/>• bool public relinquished;
                <br/>
                <br/>• uint256 public storageSpaces;
                <br/>
                <br/>• address[] public privilegeList;
                <br/>
                <br/>• mapping(address =&gt; bool) delegates;
                <br/>• mapping(address =&gt; uint256) privileged; 
                <br/>• mapping(address =&gt; string) openSourceLink;

                <br/><br/>

                <b>The "HoneyBadgerDAO" model differs in its storage layout:</b>
                <br/>
                <br/>• address[] public dormantProposals;
                <br/>• address[] public activeProposals;
                <br/>• address[] public privilegeList
                <br/>
                <br/>• bool public fullyInitialized;
                <br/>• bool private init_stage_1;
                <br/>• bool private init_stage_2;
                <br/>• bool public verified;
                <br/>• bool public permissionGateEnabled;
                <br/>• bool public privateMembersEnabled;
                <br/>
                <br/>• uint256 public storageSpaces;
                <br/>
                <br/>• mapping(address =&gt; uint256) privileged; 
                <br/>• mapping(address =&gt; string) openSourceLink;
                <br/>• mapping(address =&gt; mapping(uint256 =&gt; bool)) permittedFunctions;
                <br/>• mapping(address =&gt; mapping(uint256 =&gt; bool)) privateMemberViewable;

                <br/>• address public _deployer;

                <br/><br/>

                A few interesting factors to note: <i>privilegeList[]</i> is a traversable array 
                that tracks all addresses with heightened permissions.  <i>Privileged</i> is a mapping 
                of address to uint that tracks the specific permission level held by an address 
                with heightened permissions.  Note that features with some overhead such as 
                the permission gate and private members can be disabled in the HoneyBadger DAO model.  
                Furthermore, HoneyBadger DAO is highly-configurable, featuring a three-stage initialization 
                process.  

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                Storage Space Management
            </BasicHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                As we have previously mentioned, HoneyBadger directly manages the storage space, creating its 
                own partitioned storage space abstractions within the 256<span className = "superscript">256 </span> 
                available slots in a smart contract's aggregate storage space.  Each partition can track 
                5,000,000,000 indvidual entries.  

            </BasicText>


        </PageWrapper>
    )
}