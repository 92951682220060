export const introVertex = `

    uniform float u_scrollTime;
    uniform float u_unscrollTime;
    uniform float u_ambientTime;
    uniform float u_scroll;
    uniform float u_scrollTransition;
    varying vec2 vUv;
    uniform float u_time;
    attribute vec3 np;
    attribute vec3 op;
    uniform float u_opacity;

    //	Classic Perlin 3D Noise 
    //	by Stefan Gustavson
    //
    vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
    vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
    vec3 fade(vec3 t) {return t*t*t*(t*(t*6.0-15.0)+10.0);}
    
    float cnoise(vec3 P){
      vec3 Pi0 = floor(P); // Integer part for indexing
      vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
      Pi0 = mod(Pi0, 289.0);
      Pi1 = mod(Pi1, 289.0);
      vec3 Pf0 = fract(P); // Fractional part for interpolation
      vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
      vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
      vec4 iy = vec4(Pi0.yy, Pi1.yy);
      vec4 iz0 = Pi0.zzzz;
      vec4 iz1 = Pi1.zzzz;
    
      vec4 ixy = permute(permute(ix) + iy);
      vec4 ixy0 = permute(ixy + iz0);
      vec4 ixy1 = permute(ixy + iz1);
    
      vec4 gx0 = ixy0 / 7.0;
      vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
      gx0 = fract(gx0);
      vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
      vec4 sz0 = step(gz0, vec4(0.0));
      gx0 -= sz0 * (step(0.0, gx0) - 0.5);
      gy0 -= sz0 * (step(0.0, gy0) - 0.5);
    
      vec4 gx1 = ixy1 / 7.0;
      vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
      gx1 = fract(gx1);
      vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
      vec4 sz1 = step(gz1, vec4(0.0));
      gx1 -= sz1 * (step(0.0, gx1) - 0.5);
      gy1 -= sz1 * (step(0.0, gy1) - 0.5);
    
      vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
      vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
      vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
      vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
      vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
      vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
      vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
      vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);
    
      vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
      g000 *= norm0.x;
      g010 *= norm0.y;
      g100 *= norm0.z;
      g110 *= norm0.w;
      vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
      g001 *= norm1.x;
      g011 *= norm1.y;
      g101 *= norm1.z;
      g111 *= norm1.w;
    
      float n000 = dot(g000, Pf0);
      float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
      float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
      float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
      float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
      float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
      float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
      float n111 = dot(g111, Pf1);
    
      vec3 fade_xyz = fade(Pf0);
      vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
      vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
      float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
      return 2.2 * n_xyz;
    }

    vec3 applyNoise(vec3 pos)
    {
        // Adjust the scale of the noise to control the 'floatiness'
        float noiseScale = 0.5; // Scale of the noise
        float floatIntensity = 2.; // How much the position is affected

        // Offset in the noise space to ensure continuous movement
        vec3 noiseOffset = vec3(u_ambientTime * 0.2); 

        // Calculate noise-based displacement
        float noiseX = cnoise(pos * noiseScale + noiseOffset);
        float noiseY = cnoise((pos + vec3(100.0)) * noiseScale + noiseOffset); // Offset pos for independent Y
        float noiseZ = cnoise((pos + vec3(200.0)) * noiseScale + noiseOffset); // Offset pos for independent Z

        // Apply noise-based displacement
        pos += vec3(noiseX, noiseY, noiseZ) * floatIntensity;
        return pos;
    }

    float cubicEaseOut(float t) {
        return 3.0 * t * t - 2.0 * t * t * t;
    }

    vec3 handleAmbientTransition(vec3 pos)
    {
        vec3 originalPosition = mix(op, np, min(u_scrollTime * .3, 1.));
        pos = applyNoise(originalPosition);

        vec3 endPosition = mix(originalPosition, pos, u_ambientTime * .3);
        return endPosition;
    }


    void main() 
    {
        vUv = uv;

        // Normalize u_scrollTime to the range [0, 1] for the easing calculation
        float normalizedTime = clamp(u_scrollTime / 3.34, 0.0, 1.0); // Assuming 3.34 is the duration for the transition
        float easeFactor = cubicEaseOut(normalizedTime);

        // Apply the easing function to the transition
        vec3 pos = mix(op, np, easeFactor);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, u_opacity);
      }

`

/*
    vec3 fractal1(float time)
    {
        vec2 normalizedCoord1 = (vUv * 2.) - 1.;

        // Calculate distance from (0, 0)
        float dist = distance(normalizedCoord1, vec2(0.));

        //calculate effect based on distance from origin
        //float effect = sin(dist * 10.0 - time * cos(vUv.y * time * .1)) * exp(-dist * 5.0);

        float effect = sin(dist * abs(vUv.x * time * .4)) * cos(vUv.y * time * .25);

        float i = 0.;
        for(;i < 32.;)
        {
            normalizedCoord1 = abs(normalizedCoord1);
            normalizedCoord1 -= .35 + effect;
            
            normalizedCoord1 *= mat2(
                cos(effect), -sin(effect),
                sin(effect), cos(effect)
            );

            i++;
        }

        float currentBrightness = length(normalizedCoord1 * vec2(0.11));
        vec3 col1 = vec3(currentBrightness);
        float factor = smoothstep(0.0, .8, currentBrightness);
        col1 *= 1.0 - factor;

        return col1;

    }
*/

export const introFragment = `

    uniform float u_init;
    uniform float u_time;
    uniform float u_time2;
    uniform float u_time3;
    uniform float u_transition1;
    uniform float u_transition2;
    uniform float u_hovered;
    uniform float u_opacity;
    uniform float u_phase;
    uniform float u_timeflop;

    varying vec2 vUv;

    vec3 fractal1(float time)
    {
        vec2 normalizedCoord1 = (vUv * 2.) - 1.;

        // Calculate distance from (0, 0)
        float dist = distance(normalizedCoord1, vec2(0.));

        //calculate effect based on distance from origin
        //float effect = sin(dist * 10.0 - time * cos(vUv.y * time * .1)) * exp(-dist * 5.0);

        float effect = sin(dist * sin(abs(vUv.x * time * .4))) * cos(vUv.y * time * .25);

        float i = 0.;
        for(;i < 32.;)
        {
            normalizedCoord1 = abs(normalizedCoord1);
            normalizedCoord1 -= .35 + effect;
            
            normalizedCoord1 *= mat2(
                cos(effect), -sin(effect),
                sin(effect), cos(effect)
            );

            i++;
        }

        float currentBrightness = length(normalizedCoord1 * vec2(0.11));
        vec3 col1 = vec3(currentBrightness);
        float factor = smoothstep(0.0, .8, currentBrightness);
        col1 *= 1.0 - factor;

        return col1;

    }

    vec3 fractal2(float time)
    {
        float angle = (time * .075) - 8.;
        vec2 normalizedCoord = (vUv * 2.) - 1.;
        float shearFactor = .5 + sin(time * .5) * .1;

        // Calculate distance to mouse position
        float dist = distance(normalizedCoord, vec2(0.));
        float mouseEffect = sin(dist * 10.0 - time * cos(vUv.y * time * .1)) * exp(-dist * 5.0);

        float i = 0.;
        for(;i < 32.;)
        {
            normalizedCoord = abs(normalizedCoord);
            normalizedCoord -= .25 + mouseEffect;
            

            normalizedCoord *= mat2(
                cos(angle), -sin(angle),
                sin(angle), -cos(angle * .46)
            );

            i++;
        }

        float currentBrightness = length(normalizedCoord * vec2(0.26));
        vec3 col = vec3(currentBrightness);
        float factor = smoothstep(0.0, .8, currentBrightness);
        col *= 1.0 - (factor * .25);

        return col;
    }

    void main()
    {

        if(u_phase == 0.)
        {
            if(u_hovered == 0.)
            {
                //transition FROM hovered
                if(u_transition1 <= 100.)
                {
                    vec3 col = fractal1(u_time);
                    vec3 col2 = fractal2(u_time2);

                    //mix based on transition1

                    col = mix(col, col2, (100. - u_transition1) / 100.);

                    if(u_init > 0.)
                    {
                        col = mix(col, vec3(0.), u_init / 100.);
                    }

                    
                    if(u_timeflop > 0.)
                    {
                        col = mix(col, fractal1(u_timeflop), u_timeflop / 2.);
                    }

                    gl_FragColor = vec4(col, u_opacity);
                }
                else
                {
                    //default
                    vec3 col = fractal1(u_time);

                    if(u_init > 0.)
                    {
                        col = mix(col, vec3(0.), u_init / 100.);
                    }

                    if(u_timeflop > 0.)
                    {
                        col = mix(col, fractal1(u_timeflop), u_timeflop / 2.);
                    }

                    gl_FragColor = vec4(col, u_opacity);
                }

            }
            //hovered case
            else if(u_hovered == 1.)
            {

                //Default TO hovered transition
                if(u_transition1 >= 0.)
                {
                    vec3 col = fractal1(u_time);
                    vec3 col2 = fractal2(u_time2);

                    //mix based on transition1

                    col = mix(col2, col, u_transition1 / 100.);


                    if(u_init > 0.)
                    {
                        col = mix(col, vec3(0.), u_init / 100.);
                    }

                    gl_FragColor = vec4(col, u_opacity);
                }
                else
                {
                    //default hovered after transition
                    vec3 col = fractal2(u_time2);

                    if(u_init > 0.)
                    {
                        col = mix(col, vec3(0.), u_init / 100.);
                    }

                    gl_FragColor = vec4(col, u_opacity);
                }
            }
        }
        else
        {
            if(u_transition1 >= 0.)
            {
                vec3 col = fractal1(u_time);
                vec3 col2 = fractal2(u_time2);
                vec3 col3 = fractal2(u_time3);

                //mix based on transition1

                col = mix(col2, col, u_transition1 / 100.);

                gl_FragColor = vec4(col, u_opacity);
            }
            else if(u_transition2 >= 0.)
            {
                vec3 col2 = fractal2(u_time2);
                vec3 col3 = fractal2(u_time3);

                //mix
                col2 = mix(col3, col2, u_transition2 / 100.);
                gl_FragColor = vec4(col2, u_opacity);
            }
            else
            {
                //default hovered after transition
                vec3 col = fractal2(u_time3);
                gl_FragColor = vec4(col, u_opacity);
            }
        }

    }

`
