import styled from "styled-components"

export const BigHeading = styled.h1`

    color: #fafafa;
    font-size: 3.5vmax;
    width: 50vmax;
    text-align: left;

`

export const BigHeading2 = styled.h1`

    color: #fafafa;
    font-size: 36px;
    width: 50vmax;
    text-align: left;

`

export const BasicText = styled.p`

    color: #c9c9c9;
    width: 50vmax;
    text-align: left;
`

export const BasicHeading = styled.h2`

    color: #c9c9c9;
    width: 50vmax;
    text-align: left;
`