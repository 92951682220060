import '../../../../css/keyFonts.css';
import '../../../../css/TextStyles.css';
import common from '../common.module.css';
import styles from './ContentSection2.module.css'
import xLogo from '../../../../assets/xLogoClean.png';
import tgLogo from '../../../../assets/telegramLogoClean.png';
import tokenEcosystem from '../../../../assets/tokenecosystem.png';

export default function ContentSection2()
{
    return(
        <div className={styles.Container}>
            <iframe
                    style={{
                        marginTop: "60px",
                        borderRadius: "10px",
                        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)",
                    }}
                    src="https://www.youtube.com/embed/LngHy8ixdvs?start=32"
                ></iframe>

            <p className={`${common.Sub} ${styles.SmallHeader} HN`}>
                Understand HoneyBadger in Minutes!
            </p>

            <h2 style = {{marginTop: "100px"}} className = {`${common.SubWhite} HN`}>Utility-Driven Token Ecosystem</h2>
            <p style = {{textAlign: 'left', width: "100%", marginTop: "10px"}} className = {`${common.Sub} HN`}> 
            Our wider ecosystem will be managed by HoneyBadger token, which comes with a powerful 
            staking model (5x more efficient than the standard implementation!) 
            and deflationary weekly token distributions for the community.
            </p>

            <ul style = {{marginTop: '10px'}} className = {`${common.Sub} ${common.ListStyle} HN`}>
                <li>Token Governance</li>
                <li>Token grants for projects using HoneyBadger </li>
                <li>Preferential, boosted grants for projects who integrate 
                HoneyBadger Token into their ecosystem.</li>
            </ul>

            <img src = {tokenEcosystem} style = {{height: "75%", width: "75%",
            marginTop: "20px", marginBottom: "20px"}}/>

            <p style = {{textAlign: 'left', width: "100%", marginTop: "10px"}} className = {`${common.Sub} HN`}> 
            The community will earn HoneyBadger token through staking and the 
            weekly distribution, and be able to use it across an ever-growing 
            collection of cutting-edge systems throughout the Ethereum network!  
            </p>

            <h2 style = {{marginTop: "100px"}} className = {`${common.SubWhite} HN`}>
                Join the Badger Clan!
            </h2>
            <p style = {{textAlign: 'center', width: "100%", marginTop: "10px"}} 
            className = {`${common.Sub} HN`}> 
                Join the rapidly-growing group of HoneyBadger enthusiasts 
                enjoying exclusive events, news, giveaways, and more! 
            </p>
            <div className={`${styles.Row}`} style = {{marginTop: "40px"}}>
                <a 
                    href="https://twitter.com/honeybadgerweb3" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <img className = {styles.ImageButtons} src = {xLogo} />
                </a>

                <a 
                    href="https://t.me/honeybadgersafeguard" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style = {{textDecoration: 'none'}}
                >
                    <img className = {styles.ImageButtons} src = {tgLogo} />
                </a>
            </div>
        </div>
    )
}