import SidebarItem from "../SidebarComponents/SidebarItem";

export function NonTechnical({page, setPage, mobileSidebarOpen, setMobileSidebarOpen})
{
    return [
        <SidebarItem 
            key = 'NT1' 
            label = "HoneyBadger Framework" 
            page = {page} 
            pageNumber={1.1} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,

        <SidebarItem 
            key = 'NT2' 
            label = "Token Ecosystem" 
            page = {page} 
            pageNumber={1.2} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,

        <SidebarItem 
            key = 'NT3' 
            label = "Genesis Token" 
            page = {page} 
            pageNumber={1.3} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,

        <SidebarItem 
            key = 'NT4' 
            label = "Roadmap" 
            page = {page} 
            pageNumber={1.4} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />
    ]
}

export function VideoGuides({page, setPage, mobileSidebarOpen, setMobileSidebarOpen})
{
    return [
        <SidebarItem 
            key = 'VG1' 
            label = "Blockchain, Smart Contracts, Scalability" 
            page = {page} 
            pageNumber={5.1} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,

        <SidebarItem 
            key = 'VG2' 
            label = "HoneyBadger Framework made simple." 
            page = {page} 
            pageNumber={5.2} 
            setPage = {setPage}
            mobileSidebarOpen = {mobileSidebarOpen}
            setMobileSidebarOpen = {setMobileSidebarOpen}
        />,
    ]
}
