import { H, H2, Li, P, P2, S, Sm, Tab, Ul, White } from "../Common/CommonElements";
import PageLayout from "../Common/PageLayout";
import styles from '../Common/page.module.css';
import GetVote from '../../../../assets/GetVote.svg'
import EarnPoints from '../../../../assets/EarnPoints.svg';
import Points from '../../../../assets/Points.svg';
import Staking from '../../../../assets/Staking.svg';
import Eco from '../../../../assets/Ecosystem.svg';

export default function Page_1_2({mobileMode})
{
    return(
        <PageLayout mobileMode={mobileMode}>
            <h2 className={styles.PageHeader}>
                Introduction
            </h2>
            <P2>
                <Tab />
                The HoneyBadger Token Ecosystem  
                integrates governance, rewards, and collaboration into a cohesive 
                system, powered by the HoneyBadger utility token. It serves as the 
                participatory backbone of the HoneyBadger framework, which enables Solidity 
                developers to create scalable, interoperable, and efficient smart contract systems. 
                By linking community contributions, decentralized decision-making, and developer 
                rewards, the token ecosystem ensures that all stakeholders 
                can actively shape the ecosystem while developing a louder voice and 
                access to a wider range of services.

                <br/><br/>
                <Tab />
                At the heart of this ecosystem is HoneyBadger Token, a multi-purpose token that provides utility 
                across several key functions. Governance is streamlined through a unique mechanism 
                that uses Vote NFTs to enable transparent and flexible decision-making. 
                Contributors are incentivized through a points-based token distribution model 
                that ties 
                weekly HoneyBadger Token distribution to meaningful actions such as voting, system verification, 
                and active engagement. Developers, in turn, benefit from grant programs that 
                empower projects built on HoneyBadger to drive community engagement, 
                while encouraging HoneyBadger Token integration within new 
                Web3 applications. Together, these features create an environment that is 
                participatory, sustainable, and scalable.

                <Tab />

                <h2 className={styles.PageHeader}><White>Tokenized Governance With Vote NFTs
                </White></h2>
                <H2>The Importance of Governance in HoneyBadger</H2>
                <P2>
                    <Tab />Effective governance is a cornerstone of the HoneyBadger ecosystem, ensuring 
                    that the community has a direct voice in shaping its future. HoneyBadger 
                    introduces a streamlined governance model powered by Vote NFTs, which 
                    ties decision-making directly to $HB token utility. Our approach fosters 
                    transparency, decentralization, and active participation while incorporating 
                    a deflationary mechanism that ensures long-term sustainability while helping 
                    active participants to gain more sway.
                </P2>

                <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />
                        <div className={styles.Center} >
                            <img className = {styles.Img} src = {GetVote} />
                        </div>

                <H2>How the Model Works</H2>
                <P2>
                <Tab />Governance in HoneyBadger is built around Vote NFTs, which are minted 
                using $HB tokens. These NFTs enable holders to participate in ecosystem 
                proposals while introducing unique mechanics to improve accessibility and 
                efficiency:
                </P2>

                <Ul>
                    <Li>
                        Users spend $HB to mint Vote NFTs, 
                        locking the spent tokens in the process.
                    </Li>
                    <Li>
                            Vote NFTs apply to a single proposal.  
                            Proposal creators can set the maximum vote count.
                    </Li>
                    <Li>
                            When voting concludes, Vote NFTs return 95% of the 
                            locked tokens, burning 5%.
                    </Li>
                    <Li>
                            Voters receive temporary and/or permanent <i>points. </i> 
                            Some proposals will also have a $HB or $HBGEN reward 
                            pool attached.  
                            Temporary points give a big boost in the next 
                            token distribution event, while permanent points 
                            slightly increase the user's base event allocation.
                    </Li>
                </Ul>
            </P2>
            <S />
            <H>Points System</H>
            <P2>
                <Tab />
                The HoneyBadger ecosystem uses a points-based rewards system to 
                incentivize meaningful engagement and foster ongoing participation. 
                This system directly ties user contributions to $HB token distribution, 
                ensuring that those who actively support the ecosystem—whether by voting, 
                verifying deployments, or engaging with community initiatives—are fairly 
                rewarded. By gamifying participation and providing clear, tangible benefits, 
                the points-based model ensures a dynamic, engaged, and thriving community.
            </P2>

            <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />
            <div className={styles.Center} >
                <img className = {styles.Img} src = {EarnPoints} />
            </div>

            <H2>Permanent Points</H2>
            <P2>
                These points represent long-term contributions to the ecosystem.  
                Actions such as holding exclusive NFTs, participating in governance, 
                winning events, or verifying 
                deployed systems may grant permanent points.
            </P2>

            <H2>Temporary Points</H2>
            <P2>
                These points are event-specific and expire after a variable number of 
                token distribution cycles. Temporary points are designed to 
                reward short-term actions, such as voting in a specific 
                governance event, and provide a significant boost to the 
                user's next token allocation.
            </P2>

            <H2>How Points Earn You $HB</H2>
            <P>
                HoneyBadger uses a deflationary token distribution model, 
                with mint events occurring on a weekly basis.  Token allocations 
                are determined entirely by points.
            </P>
            
            <H2>Earning Points</H2>

        <P2>
            Users accumulate points by performing specific actions within the HoneyBadger ecosystem:</P2>
            <Ul>
                <Li>
                    Voting in Governance Events:
                </Li>
                <P2>
                    Participants earn points when they use Vote NFTs to cast votes on proposals.
                </P2>
            </Ul>

            <Ul>
                <Li>
                    Verifying Deployed Systems:
                </Li>
                <P2>Projects can submit their smart contracts to be verified as a recognized 
                entity, making them eligible for closer integration with the token ecosystem.
                Users who find issues in submitted code can earn large point bounties.  
                </P2>
            </Ul>

            <Ul>
                <Li>
                    Holding NFTs:
                </Li>
                <P2>
                    Exclusive HoneyBadger NFTs grant their holder points applied to  
                    every event.
                </P2>
            </Ul>

            <Ul>
                <Li>
                    Engaging in Community Initiatives: 
                </Li>
                <P2>
                Users can earn points by interacting with specific 
                ecosystem content, such as tutorials, hackathons, Telegram events, or 
                platform-integrated challenges.
                </P2>
            </Ul>
            <S />
            <H>
                Developer Grants and Ecosystem Incentives
            </H>
            <P2>
            <Tab />
                The HoneyBadger Developer Grant Program connects the HoneyBadger 
                community with a wider network of projects, encouraging collaboration 
                and expanding the utility of the $HB token. By supporting developers 
                who integrate $HB into their applications and build on the HoneyBadger 
                framework, the program helps drive engagement from the HoneyBadger 
                community while increasing the token's functionality across decentralized 
                ecosystems. This approach ties the community together through meaningful 
                projects while strengthening $HB's role in the Web3 space.
            </P2>

        <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />
            <div className={styles.Center} >
                <img className = {styles.Img} src = {Points} />
            </div>

        <H2>How the Grant Program Works</H2>
        <P2>
        The Developer Grant Program supports projects that 
        align with the HoneyBadger ecosystem and contribute 
        to its growth. Key aspects of the program include:
        </P2>
        <Ul>
            <Li>
            Community Engagement:
            </Li>
            <P2>
                Grant recipients can drive engagement 
                from the central community, helping 
                them build traction.
            </P2>
            <Li>
            Expanding Token Utility:
            </Li>
            <P2>
            Grants focus on funding projects that 
            add use cases for $HB, such as DeFi platforms, DAOs, 
            or blockchain-based gaming systems. Each integration expands 
            the functionality of the token beyond the core framework.
            </P2>
        </Ul>
        <S />
        <H>
            Staking
        </H>
        <P2>
            Staking in the HoneyBadger Token Ecosystem allows $HB holders to actively 
            support the network’s growth and sustainability. By locking their tokens 
            into the staking protocol, participants reduce circulating supply while 
            earning passive rewards. This process encourages long-term engagement, 
            aligns incentives, and helps maintain stable token circulation.
        </P2>

        <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />
            <div className={styles.Center} >
                <img className = {styles.Img} src = {Staking} />
            </div>

        <H2>
            Staking Model
        </H2>
        <P2>
            When users stake tokens to the pool, 10% of their stake is 
            immediately distributed to all current stakers.  This is 
            repeated on the remaining principal when a user unstakes.
        </P2>

        <H2>
            Reward Calculation
        </H2>
        <P2>
            The portion of each fee that previous stakers earn 
            is equivalent to their staked tokens divided by 
            the total staked tokens at that time.
        </P2>

        <H2>
            Fee Distribution
        </H2>
        <P2>
            95% of each staking fee is distributed back to the community.  
            4% of the fee is burned, and 1% goes to the HoneyBadger team.
        </P2>
        <S />
        <H>
            Broader Utility Across Web3
        </H>
        <P2>
            <Tab />The $HB token, combined with the HoneyBadger framework, provides a complete 
            foundation that primes projects to build traction and thrive long-term. By 
            integrating $HB, companies couple their  
            access to a scalable technical infrastructure with a robust token ecosystem, 
            effectively merge their project into a wider sphere of activity, and can be 
            further empowered by grants or point allocations. <b>Critically, the HoneyBadger team 
            doesn't make any demands: the framework is free and open-sourced, and grants don't 
            require projects to give supply.</b>  Instead, they only need to leverage our 
            framework and create an on-ramp from their ecosystem to ours.
        </P2>

        <div style = {{height: (mobileMode === true) ? "40px" : '20px'}} />
            <div className={styles.Center} >
                <img className = {styles.Img} src = {Eco} />
            </div>
        </PageLayout>
        
    )
}