import '../../../../css/keyFonts.css'
import { NonTechnical, VideoGuides } from '../Sections/NonTechnical'
import ItemSegment, { HiddenSegment } from './ItemSegment'
import styles from './SidebarItems2.module.css'
import Exit from '../../../../assets/Exit.svg'

export default function SidebarItems2({page, setPage})
{
    const NonTechnicalItems = NonTechnical({page, setPage});
    const VideoGuideItems = VideoGuides({page, setPage});

    return(
        <div className={styles.SidebarItemsContainer}>
            <ItemSegment title = "Fundamentals" items={NonTechnicalItems} page = {page}/>
            <ItemSegment title = "Interactive Guides"  page = {page}/>
            <ItemSegment title = "Using HoneyBadger" page = {page}/>
            <ItemSegment title = "Functions" page = {page}/>
            <ItemSegment title = "Video Guides" page = {page} items = {VideoGuideItems}/>
        </div>
    )
}

export function SidebarItemsMobile({
    page, 
    setPage, 
    mobileSidebarOpen, 
    setMobileSidebarOpen
})
{
    const NonTechnicalItems = NonTechnical({page, setPage, mobileSidebarOpen, setMobileSidebarOpen});
    const VideoGuideItems = VideoGuides({page, setPage, mobileSidebarOpen, setMobileSidebarOpen});
    return(
        <div className={styles.SidebarItemsContainerMobile}>
            <img src = {Exit}
            className = {styles.Exit}
            />
            <div style = {{height: "80px"}} />
            <ItemSegment 
                title = "Non-Technical" 
                items={NonTechnicalItems} 
                page = {page}/>
            <ItemSegment 
                title = "Video Guides" 
                items={VideoGuideItems} 
                page = {page}/>
        </div>
    )
}