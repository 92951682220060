import { Canvas } from "@react-three/fiber";
import React, {useEffect, useRef, useState} from "react";
import FlyingLinesInstanced from "./Effects/FlyingLinesInstanced";
import IntroBackground from "./Effects/background/introBackground";
import {CalculateScale} from '../ModelHandlers/HoneyBadgerModelHandler'
import { HoneyBadgerModelMainUpdated } from "../Models/HoneyBadgerModelMainUpdated/HoneyBadgerModelMainUpdated";
import { isDesktop, isMobile } from "react-device-detect";
import MobileBg from "./MobileBg/MobileBg";

export default function Scene(props)
{
    const [den, setDen] = useState(false);
    const [bgloaded, setBgLoaded] = useState(false);

    const fogRef = useRef();

    return(
        <Canvas style = {{width: "100vw", height: (isMobile) ? "100vh" : "100vh", borderBottom: "1px solid #1c1c1c", position: "absolute",
        background: "black"}}>
            <color attach = "background" args = {["black"]} />
            <fog attach= "fog" args = {["black", 1000, 1000]} ref = {fogRef}/>

            {
                isDesktop && 
            <HoneyBadgerModelMainUpdated
            setSwapTime = {(v) => {props.setSwapTime(v)}}
            phase = {props.phase}
            scale = {CalculateScale()} 
            rotation = {[0.0, -Math.PI / 2,0]} 
            setHovered = {(v) => {props.setHovered(v)}}
            setPhase = {(v) => {props.setPhase(v)}}
            />
            }

            {!isDesktop && 
                <MobileBg />
            }

            {isDesktop && 
                <IntroBackground 
                position = {[0,0,-20]}
                scale = {(window.innerWidth > window.innerHeight) ? 9 : 4}
                phase = {props.phase}
                hovered = {props.hovered}
                hasTransitioned = {props.hasTransitioned}
                setPhase = {(v) => {props.setPhase(v)}}
                den = {den}
                setDen = {(v) => {setDen(v)}}
                bgloaded = {bgloaded}
                setBgLoaded = {(v) => {setBgLoaded(v)}}
                />
            }
            {isDesktop && 
                <>
                    <FlyingLinesInstanced total = {[10]} position = {[0,-1,0]} wScale = {1} phase = {props.phase}/>
                    <FlyingLinesInstanced total = {[10]} position = {[50,-1,0]} phase = {props.phase} />
                </>
            }
        </Canvas>
    )
}

/*
            <Roadmap2SceneGeneric 
            position = {[0, -500, -500]}
            vis = {true}/>
*/