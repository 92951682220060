import { useState } from 'react';
import styles from './HowItWorks.module.css';

export default function HowItWorksText()
{
    const [explanationDepth, setDepth] = useState('basic');

    return(
        <div className = {styles.Container}>
            <div className={styles.HeaderContainer}>
                <h2 className={`${styles.Header} titleText`}>How it works</h2>
                <div className={styles.SelectionContainer}>
                    <div className={styles.ButtonContainer}>
                        <button className={`${styles.Button} ${styles.ButtonLeft} ${
                        (explanationDepth !== 'basic' && styles.NotSelectedButton)}`}
                        onClick = {() => {setDepth('basic')}}>Simple</button>
                        <button className={`${styles.Button} 
                        ${(explanationDepth === 'basic' && styles.NotSelectedButton)}`}
                        onClick = {() => {setDepth('advanced')}}>
                        Advanced</button>
                    </div>

                </div>
            </div>

            { (explanationDepth === 'basic') && 
                <>
                    <p className={styles.Text1}>
                    <h2 className='orbitron' style = {{color: 'white'}}>Framework 🚀</h2>
                        The HoneyBadger system is a groundbreaking Solidity development framework 
                        that redefines what’s possible in blockchain development. It provides tools and 
                        capabilities that elevate smart contract ecosystems to new levels of efficiency, 
                        scalability, and innovation. With HoneyBadger, businesses and developers gain 
                        access to a system that not only streamlines development but also delivers 
                        unmatched performance, giving them a serious competitive advantage 
                        in a rapidly evolving market.

                        <ul> 
                            <li> 
                                HoneyBadger simplifies the process of managing data and 
                                permissions for blockchain applications. Think of it as a 
                                "control center" that ensures all parts of your system work together 
                                smoothly and securely.  However, the system goes beyond simple 
                                ease of use, providing game-changing capabilities that 
                                fix serious problems that have led many projects to ruin, including 
                                upgradeability, security, and system integrity.
                            </li> 
                            <li> 
                                By using HoneyBadger, businesses can easily upgrade and expand 
                                their systems over time without needing to replace what’s 
                                already been built. This keeps systems competitive and future-proof, 
                                which are critical in the rapidly changing blockchain market. 
                            </li> 
                            <li>
                                The framework comes pre-loaded 
                                with powerful tools that save time and reduce costs, 
                                such as batch processing (reducing expensive operations), 
                                streamlined governance (shared decision-making features), 
                                and seamless integration with other popular blockchain tools. 
                            </li> 
                            <li> 
                                HoneyBadger is designed to be flexible and easy to adopt. 
                                It works with industry-standard libraries such as 
                                OpenZeppelin, meaning developers can 
                                plug it into their systems without starting from scratch or being 
                                forced to adopt unfamiliar development patterns. 
                            </li> 
                            <li> 
                                HoneyBadger will be fully open-sourced and free to use! 
                            </li> 
                            </ul> 
                        </p>
                    </>
            }

            { (explanationDepth === 'advanced') && 
            <>
                <p className = {styles.Text1}>
                    <h2 className='orbitron' style = {{color: 'white'}}>Framework 🚀</h2>
                    The HoneyBadger system is a cutting-edge Solidity development framework  
                    designed to comprehensively enhance smart contract systems, streamlining 
                    the development cycle while delivering a multifaceted competitive advantage.

                    <ul>
                        <li>
                            HoneyBadger harmonizes decentralization and scalability by coupling novel 
                            <i> fluid storage architecture </i> with advanced governance support.  
                            Learn more about the tech by checking out our ERC: Consolidated Dynamic Storage (CDS).
                        </li>  
                        <li>
                            Our system is a highly-optimized smart contract implemented in 
                            meticulously-optimized Solidity assembly. It integrates directly 
                            with your logic, managing the storage and permissions for any contract you connect to 
                            HoneyBadger.  
                            It serves as a secure core that synergizes your contract system at an unprecedented level, 
                            enabling seamless in-place interoperability between new and previously-deployed systems.
                        </li>
                        <li>
                            By integrating HoneyBadger, your systems gain instant access to a wide variety 
                            of game-changing capabilities including batch storage and retrieval, 
                            governance, and custom module support.  Additionally, HoneyBadger imposes 
                            no technical limitations, and is fully compatible with standard frameworks such as 
                            OpenZeppelin contracts.
                        </li>
                        <li> 
                            HoneyBadger will be fully open-sourced and free to use! 
                        </li> 
                    </ul>
                </p>
                </>
                }
                <>
                <p className = {styles.Text1}>
                <h2 className='orbitron' style = {{color: 'white'}}>Token 🦡</h2>
                    HoneyBadger Token is an upgradeable ERC20 staking token.  It will be 
                    used as a governance asset after the HoneyBadger framework is released.  
                    <br/><br/>
                    <b>Notable Features: </b>

                    <ul>
                        <li>
                            The HoneyBadger Token staking model benefits from our 
                            passion for optimization, offering up to 4x gas savings for 
                            stake, unstake, and claim operations compared to typical 
                            implementations.  This is due to a novel low-level optimization 
                            technique designed specifically for this token contract.
                        </li>
                        <li>
                            Our <i> Token Grants </i> feature will support 
                            projects that use HoneyBadger, allowing the community to vote on 
                            promising HoneyBadger projects to receive large sums of HoneyBadger
                            Tokens.  Projects who integrate HoneyBadger token into their 
                            ecosystem gain preferential treatment.  We want the token to 
                            not only help projects gain traction, but also serve as a bridge 
                            between the entire HoneyBadger ecosystem.
                        </li>
                        <li>
                            HoneyBadger token will also be used for governance, allowing users 
                            to vote on new features and modules they would like to see added 
                            in new models.
                        </li>
                    </ul>
                </p>
            </>

        </div>
    )
}