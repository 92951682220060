import '../../../../css/keyFonts.css'
import styles from './page.module.css';
import logo from '../../../../assets/logoTPB.png';
import { Link } from 'react-router-dom';

export default function PageLayout({children, mobileMode})
{
    return(
        <div className = {styles.Container}>

            <div className = {`${styles.ContentContainer} ${mobileMode ? '' : styles.Desktop}`}>

                <div className = {styles.InnerContainer}>
                    <div style = {{height: "50px"}}/>
                    {children}
                    <div style = {{height: "50px"}}/>
                </div>

            </div>

        </div>
    )   
}