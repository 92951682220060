import Roadmap from '../../../../assets/Roadmap.svg';
import pageStyles from '../Common/page.module.css';
import styles from '../Common/page.module.css';
import { isMobile } from 'react-device-detect';
import PageLayout from '../Common/PageLayout';
import {P, H, Tab, Ul, Li, Sm, BigLi, H2, S, White} from '../Common/CommonElements'

export default function Page1_4({mobileMode})
{
    return(
        <PageLayout mobileMode={mobileMode} >
            <div className = {pageStyles.LongImageContainer}>
                <img className={pageStyles.LongImage} src = {Roadmap} alt = "HoneyBadger roadmap" />
            </div>

        </PageLayout>
    )
}