import '../../css/TextStyles.css';
import { useEffect, useRef } from 'react';
import xlogo from '../../assets/xlogo2.png';
import tglogo from '../../assets/tglogo2.png';
import styles from './MobileFloatingMenu.module.css';
import { Link } from 'react-router-dom';

export default function MobileFloatingMenu(props)
{

    const containerRef = useRef();

    useEffect(() => {
        // Ensure the scroll starts at the very beginning
        if (containerRef.current) {
            containerRef.current.scrollLeft = 0;
        }
    }, []);

    return(
        <>
        <div className = {styles.Container} ref={containerRef}>
            <div className={styles.Col}>
                <h2>Scroll</h2>
                <p style = {{margin: 0, padding: 0, color: 'white'}}>→</p>
            </div>
            <div className={styles.Col}>
                <a 
                    href="https://t.me/honeybadgersafeguard" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <img src = {tglogo} style = {{width: "16px"}}/>
                </a>
                <a 
                    href="https://t.me/honeybadgersafeguard" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style = {{textDecoration: 'none'}}
                >
                    <h2>Telegram</h2>
                </a>
            </div>
            <div className={styles.Col} >
                <a 
                    style = {{textDecoration: "none"}}
                    href="https://docs.google.com/forms/d/e/1FAIpQLSegpfAL2oZ1N8vXd3-nwLpIacpMzklDaT_CtQ8EJxd6yp2B0Q/viewform?usp=header" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <h2 className={styles.Icon}>🪂</h2>
                </a>
                <a 
                    style = {{textDecoration: "none"}}
                    href="https://docs.google.com/forms/d/e/1FAIpQLSegpfAL2oZ1N8vXd3-nwLpIacpMzklDaT_CtQ8EJxd6yp2B0Q/viewform?usp=header" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <h2>Airdrop</h2>
                </a>
            </div>
            <div className={styles.Col}>
                <a 
                    href="https://twitter.com/honeybadgerweb3" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <img src = {xlogo} style = {{width: "16px"}}/>
                </a>
                <a 
                    href="https://twitter.com/honeybadgerweb3" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style = {{textDecoration: 'none'}}
                >
                    <h2>X.com</h2>
                </a>
            </div>
            <div className={styles.Col} >
                <a 
                    href="https://ethereum-magicians.org/t/erc-7844-consolidated-dynamic-storage-cds/22217" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style = {{textDecoration: "none"}}
                >
                    <h2 className={styles.Icon}><span className = {"ShinyText"}>⧫</span></h2>
                </a>
                <a 
                    href="https://ethereum-magicians.org/t/erc-7844-consolidated-dynamic-storage-cds/22217" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style = {{textDecoration: "none"}}
                >
                <h2>ERC-7844 </h2>
                </a>
            </div>
            <div className={styles.Col}>
                <Link
                style={{textDecoration: 'none'}}
                    to = {{
                        pathname: '/docs'
                    }}>
                    <h2 className={styles.Icon}>📖</h2>
                    <h2>How it Works</h2>
                </Link>
            </div>
            <div className={styles.Col}>
                <Link 
                style={{textDecoration: 'none'}}
                    to = {{
                        pathname: '/docs',
                        search: '?section=1.4',
                        state: {section: 1.4}
                    }}>
                    <h2 className={styles.Icon}>🗺️</h2>
                    <h2>Roadmap</h2>
                </Link>
            </div>
            <div className={styles.Col}>
                <Link 
                style={{textDecoration: 'none'}}
                    to = {{
                        pathname: '/docs',
                        search: '?section=1.4',
                        state: {section: 1.2}
                    }}>
                    <h2 className={styles.Icon}>📈</h2>
                    <h2>Tokenomics</h2>
                </Link>
            </div>
        </div>
        </>
    )

}