import '../../../css/TextStyles.css'
import menu from '../../../assets/menu.png'
import {animated, useSpring} from 'react-spring'
import { useEffect, useState, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { HoneyBadgerModelBarebones } from '../../Models/HoneyBadgerModel'
import { AspectManager } from '../../Helpers/ClipManager'
import SidebarItems from './SidebarItems'
import arrow from '../../../assets/arrow.png'
import styled from 'styled-components'

import styles from './Sidebar.module.css'
import SidebarItems2 from './SidebarComponents/SidebarItems2'

export default function Sidebar(props)
{
    return(
        <div className = {styles.Container}>
            
            <Canvas style = {{height: "30vh"}}>
                <color attach = "background" args = {["black"]} />
                <HoneyBadgerModelBarebones scale = { AspectManager(8) } rotation = {[0, 0, 0]}/>
                <pointLight intensity = {50} />
            </Canvas>

            <SidebarItems2 
                setPage={props.setPage} 
                page = {props.page}
                
            />

        </div>
    )
}